import { CircularProgress, CircularProgressProps, Box } from '@mui/material';

export const MuiSpinner = ({ sx = {}, ...spinnerProps }: CircularProgressProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        minHeight: '50vh',
        alignItems: 'center',
        ...sx,
      }}
    >
      <CircularProgress id={'loader'} {...spinnerProps} />
    </Box>
  );
};
