import { memo, useEffect } from 'react';
import { useAtomValue } from 'jotai';
import JSCookies from 'js-cookie';
import { IS_PRODUCTION } from '@/config/Constants';
import { env } from '@/config/envs';
import { PUBLIC_DEAL_LISTING_COOKIE } from '@/containers/NewProject/utils';
import { userAtom } from '@/services/atoms.service';
import { fetchService } from '@/services/fetch.service';
import { checkApiSuccessResponse, clientLog, isLoadedInsideIframe } from '@/utils';

function ServiceAccountProjectListener() {
  const user = useAtomValue(userAtom);

  const callTransferPendingProject = async (masterProjectId: string) => {
    const controller = new AbortController();
    fetchService({
      url: `/project/transfer/owner`,
      method: 'PUT',
      signal: controller?.signal,
      data: { masterProjectId },
    })
      .then((r) => {
        if (checkApiSuccessResponse(r?.data?.status || r?.status)) {
          JSCookies.remove(PUBLIC_DEAL_LISTING_COOKIE, {
            secure: false,
            httpOnly: false,
            domain: IS_PRODUCTION ? env.NEXT_PUBLIC_DOMAIN?.split('https://')[1] : 'localhost',
          });
        } else {
          throw new Error(r?.error || `Error transferring project: ${masterProjectId}`);
        }
      })
      .catch((e) => {
        // Do not log abort error
        if (!(e instanceof DOMException && e.name == 'AbortError')) {
          clientLog({ message: e?.message || `Error transferring project: ${masterProjectId}` });
        }
      });
  };

  useEffect(() => {
    if (user?.sub) {
      const project = JSCookies.get(PUBLIC_DEAL_LISTING_COOKIE);
      if (project && !isLoadedInsideIframe()) {
        const masterProjectId = JSON.parse(project)?.masterProjectId;
        if (masterProjectId) {
          callTransferPendingProject(masterProjectId);
        }
      }
    }
  }, [user?.sub]);

  return null;
}

export default memo(ServiceAccountProjectListener);
