import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { BaseTextFieldProps, MenuItem, TextField } from '@mui/material';

type MandatoryKeys = 'label' | 'value' | string;
export interface ISelectTextfield extends Omit<BaseTextFieldProps, 'error'> {
  control?: Control<any>;
  shrink?: boolean;
  options: Array<string | { [key: MandatoryKeys]: any }>;
  error?: { message: string } | string | boolean;
  noOptionalHelper?: boolean;
  name: string;
  native?: boolean;
  valueAsObject?: boolean;
  helperText?: string;
  onChange?: (e?: any, v?: Array<{ label: string; value: string }>) => void;
}

function SelectTextfield({
  required = false,
  error,
  control = undefined,
  name,
  label,
  sx = {},
  disabled = false,
  variant = 'filled',
  shrink = undefined,
  options = [],
  noOptionalHelper = false,
  native = true,
  onChange,
  helperText,
  ...props
}: ISelectTextfield) {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ field }) => (
        <TextField
          fullWidth
          select
          id={name}
          variant={variant}
          label={label}
          type={'select'}
          error={!!error}
          InputLabelProps={{
            shrink,
          }}
          SelectProps={{
            native,
          }}
          required={required}
          helperText={
            helperText ||
            `${required || noOptionalHelper ? '' : 'Optional'}${
              typeof error === 'string'
                ? error
                : typeof error === 'boolean' && !!error
                ? '| This field is mandatory'
                : typeof error === 'object' && 'message' in error && error?.message
                ? ` | ${error.message}`
                : ''
            }`
          }
          {...props}
          {...field}
          disabled={disabled || field?.disabled}
          onChange={(e) => {
            field?.onChange(e?.target?.value || e);
            onChange?.(e);
          }}
          sx={{ ...sx }}
        >
          <OptionWrapper native={native} value="" />
          {options.map((opt) => {
            if (typeof opt === 'string') {
              return (
                <OptionWrapper native={native} key={opt} value={opt}>
                  {opt}
                </OptionWrapper>
              );
            }
            if (typeof opt === 'object' && 'value' in opt) {
              return (
                <OptionWrapper
                  native={native}
                  key={opt.value}
                  value={opt.value}
                  data-obj={opt}
                  disabled={opt?.disabled}
                >
                  {opt.label}
                </OptionWrapper>
              );
            }
            return null;
          })}
        </TextField>
      )}
    />
  );
}

const OptionWrapper = ({ native, children = '', disabled = false, ...props }) => {
  if (native) return <option {...props}>{children}</option>;
  return (
    <MenuItem {...props} disabled={children === '' || disabled}>
      {children}
    </MenuItem>
  );
};

export default SelectTextfield;
