import React, { memo, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { env } from '@/config/envs';
import { MuiSpinner } from './Spinner';

/**
 * This component checks wether the screen is loaded inside a popup opened from a parent window.
 * The idea is to use the to request the opener to navigate to a specific URL instead of loading the
 * URL in the popup itself.
 */
function OpenInPopupPushListener({ children }: { children: React.ReactNode }) {
  const [show, setShow] = useState(false);
  const { isReady } = useRouter();

  useEffect(() => {
    if (show === true) return;
    if (isReady && typeof window !== 'undefined') {
      if (window.opener === null) {
        return setShow(true);
      } else {
        const navigateRequest = () => {
          const url = env.NEXT_PUBLIC_DOMAIN;
          if (window.opener.parent) {
            window.opener.parent.postMessage({ type: 'NAVIGATE', url }, '*');
          } else {
            window.opener.postMessage({ type: 'NAVIGATE', url }, '*');
          }
        };
        navigateRequest();
      }
    }
  }, [isReady]);

  return show ? children : <MuiSpinner color={'primary'} />;
}

export default memo(OpenInPopupPushListener);
