import { useEffect } from 'react';
import { useSession } from 'next-auth/react';
import { isContractFullySigned } from '@/config';
import { useNotifications, useWhoami } from './api.service';

function NotificationsProvider({ children }) {
  // const setNotifications = useSetAtom(notificationsAtom);
  const { data: sessionData, status, update } = useSession();

  useNotifications(typeof window !== 'undefined' && status === 'authenticated', {
    refreshInterval: 60000,
  });
  const { data: dbData } = useWhoami(
    typeof window !== 'undefined' && status === 'authenticated',
    !isContractFullySigned(sessionData?.user) && {
      refreshInterval: 60000,
    }
  );

  useEffect(() => {
    // session can get out of sync, so update if it changes
    if (dbData && sessionData?.user) {
      const keysToSync = ['isAdminSignAgreement', 'isContractSigned', 'status'];
      for (const key of keysToSync) {
        if (sessionData.user?.[key] !== dbData[key]) {
          // session needs to be updated;
          update({ [key]: dbData[key] });
        }
      }
    }
  }, [dbData, sessionData]);

  return children;
}

export default NotificationsProvider;
