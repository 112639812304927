import { z } from 'zod';
import { createEnv } from '@t3-oss/env-nextjs';

export const env = createEnv({
  skipValidation: process.env.CI === 'true',
  server: {
    // Defaults
    ADMIN_API_BASE_URL: z.enum([
      'https://admin.stage.smat.io/api',
      'https://admin.app.smat.io/api',
    ]),
    ANALYTICS_PROPERTY_ID: z.string(),
    NEXT_SHARP_PATH: z.string(),
    ZITADEL_PROJECT_ID: z.string(), // avoid using coerce.number as it will cause numbers to overflow
    ZITADEL_ISSUER: z.enum(['https://auth.smat.io']),
    ZITADEL_ORG_ID: z.string(), // avoid using coerce.number as it will cause numbers to overflow
    ZITADEL_CLIENT_ID: z.string(), // avoid using coerce.number as it will cause numbers to overflow
    NEXTAUTH_URL: z.enum([
      'http://proxyman.debug:3000',
      'http://localhost:3000',
      'http://localhost:3001',
      'https://stage.smat.io',
      'https://app.smat.io',
    ]),
    MOBILE_SCHEMA: z.enum(['smatsa-stage', 'smatsa']),
    GCP_PROJECT_NUMBER: z.string(),

    // Keys
    DATABASE_URL: z.string(),
    SERVICE_ACCOUNT_JWT: z.string(),
    ADOBE_API_TOKEN: z.string(),
    ZITADEL_API_SERVICE_ACCOUNT_ID: z.string(),
    ZITADEL_API_SERVICE_ACCOUNT_PAT: z.string(),
    API_REVALIDATE_SECRET: z.string(),
    MINIO_ACCESS_KEY: z.string(),
    MINIO_SECRET_KEY: z.string(),
    NEXTAUTH_SECRET: z.string(),

    // External
    IS_STAGE: z.string().optional(),
  },
  runtimeEnv: {
    ADMIN_API_BASE_URL: process.env.ADMIN_API_BASE_URL,
    ANALYTICS_PROPERTY_ID: process.env.ANALYTICS_PROPERTY_ID,
    NEXT_SHARP_PATH: process.env.NEXT_SHARP_PATH,
    ZITADEL_PROJECT_ID: process.env.ZITADEL_PROJECT_ID,
    ZITADEL_ISSUER: process.env.ZITADEL_ISSUER,
    ZITADEL_CLIENT_ID: process.env.ZITADEL_CLIENT_ID,
    ZITADEL_ORG_ID: process.env.ZITADEL_ORG_ID,
    NEXTAUTH_URL: process.env.NEXTAUTH_URL,
    MOBILE_SCHEMA: process.env.MOBILE_SCHEMA,
    GCP_PROJECT_NUMBER: process.env.GCP_PROJECT_NUMBER,

    // Keys
    DATABASE_URL: process.env.DATABASE_URL,
    SERVICE_ACCOUNT_JWT: process.env.SERVICE_ACCOUNT_JWT,
    ZITADEL_API_SERVICE_ACCOUNT_ID: process.env.ZITADEL_API_SERVICE_ACCOUNT_ID,
    ZITADEL_API_SERVICE_ACCOUNT_PAT: process.env.ZITADEL_API_SERVICE_ACCOUNT_PAT,
    API_REVALIDATE_SECRET: process.env.API_REVALIDATE_SECRET,
    MINIO_ACCESS_KEY: process.env.MINIO_ACCESS_KEY,
    MINIO_SECRET_KEY: process.env.MINIO_SECRET_KEY,
    NEXTAUTH_SECRET: process.env.NEXTAUTH_SECRET,
    ADOBE_API_TOKEN: process.env.ADOBE_API_TOKEN,

    // External:
    IS_STAGE: process.env.IS_STAGE,
  },
});
