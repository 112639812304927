import { useEffect } from 'react';
import { useAtomValue } from 'jotai';
import { hotjar } from 'react-hotjar';
import { env } from '@/config/envs';
import { cookieConsentAtom } from '@/services/atoms.service';

const hotJarID = env.NEXT_PUBLIC_HJID;
const hotJarVersion = env.NEXT_PUBLIC_HJSV;

function HotJar() {
  const cookieConsent = useAtomValue(cookieConsentAtom);

  useEffect(() => {
    if (hotJarID && hotJarVersion && cookieConsent) {
      hotjar.initialize(Number(hotJarID), Number(hotJarVersion));
    }
  }, [cookieConsent]);
  return null;
}

// Identify the user
export const identifyHJUser = (userId: string, property: string) =>
  hotjar.identify(userId, { userProperty: property });
// Add an event
export const trackHJEvent = (event: string) => hotjar.event(event);
// Update SPA state
export const trackHJPageView = (path: string) => hotjar.stateChange(path);

export default HotJar;
