import * as React from 'react';
import { Controller } from 'react-hook-form';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { Paragraph } from '../Typography';
import { ISelectTextfield } from './SelectTextfield';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export type AutocompleteOption = {
  label: string;
  value: string | number;
  [key: string]: any;
};

interface AutocompleteMultiselectProps extends ISelectTextfield {
  options: Array<AutocompleteOption>;
  autoCompleteProps?: React.ComponentProps<typeof Autocomplete>;
  limitTags?: number;
  multiple?: boolean;
  disableCloseOnSelect?: boolean;
}

export default function AutocompleteMultiselect(props: AutocompleteMultiselectProps) {
  const {
    required = false,
    error,
    control,
    value = '',
    label,
    sx = {},
    disabled = false,
    variant = 'filled',
    autoCompleteProps,
    inputProps,
    limitTags,
    multiple = true,
    disableCloseOnSelect = true,
    ...rest
  } = props;

  if (!!inputProps) {
    Object.assign(rest, inputProps);
  }

  return (
    <Controller
      name={(value as string) || 'AUTOCOMPLETE_MISSING_NAME'}
      control={control}
      rules={{ required }}
      render={({ field }) => {
        return (
          <Autocomplete
            multiple={multiple}
            limitTags={limitTags}
            sx={{ ...sx }}
            id={`autocomplete-multiselect-${props.name}`}
            disabled={disabled}
            onChange={props.onChange}
            defaultValue={field.value || []}
            options={rest.options || []}
            disableCloseOnSelect={disableCloseOnSelect}
            getOptionLabel={(option: AutocompleteOption) => option?.label ?? ''}
            isOptionEqualToValue={(option, value) => option?.value === value?.value}
            renderOption={(props, option: AutocompleteOption, { selected }) => {
              return (
                <li {...props}>
                  {multiple && (
                    <Checkbox
                      icon={icon}
                      defaultValue={field.value}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                      size="small"
                      color="primary"
                    />
                  )}
                  <Paragraph>{option.label}</Paragraph>
                </li>
              );
            }}
            ChipProps={{ color: 'secondary', size: 'small' }}
            renderInput={(params) => {
              return (
                <Controller
                  name={(value as string) || 'AUTOCOMPLETE_MISSING_NAME'}
                  control={control}
                  rules={{ required }}
                  render={({ field }) => {
                    return (
                      <TextField
                        variant={variant}
                        label={label || ''}
                        placeholder={props.placeholder || ''}
                        error={!!error}
                        {...params}
                        {...field}
                        {...rest}
                        required={false}
                      />
                    );
                  }}
                />
              );
            }}
            {...autoCompleteProps}
          />
        );
      }}
    />
  );
}
