import { PaletteMode } from '@mui/material';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import {
  ACCENT_GRADIENT,
  BLACK_GRADIENT,
  PRIMARY_GRADIENT,
  SECONDARY_GRADIENT,
  TYPOGRAPHY_VARIANTS,
  sora,
  overpass,
  roboto,
} from './customizations';

const DarkSmatTheme = {
  typography: {
    fontFamily: [
      sora.style.fontFamily,
      overpass.style.fontFamily,
      roboto.style.fontFamily,
      'system-ui',
      'sans-serif',
    ].join(','),
    ...TYPOGRAPHY_VARIANTS,
  },
  palette: {
    mode: 'dark' as PaletteMode,
    common: {
      blackish: 'rgba(216, 223, 233, 0.21)',
      overlay: 'rgba(0, 0, 0, 0.6)',
      gradient: BLACK_GRADIENT,
      gray: '#6E6E6E',
      tableGray: '#B0B3B7',
      lightGray: '#E6E6E6',
      disabled: 'rgb(0,0,0,0.26)',
      bg: 'rgba(255, 255, 255, 0.05)',
      text: '#616d7e',
      white: '#fff',
      black: '#000',
      bgCard: '#2E2E2E',
      icons: '#2C3E50',
      border: '#5a5a5a',
      borderTwo: '#c5c5c5',
    },
    text: {
      primary: '#fff',
    },
    primary: {
      light: '#EAF4F3',
      main: '#178097',
      dark: '#0e313e',
      gradient: PRIMARY_GRADIENT,
    },
    secondary: {
      main: '#6166EC',
      dark: '#1e1c4a',
      gradient: SECONDARY_GRADIENT,
    },
    accent: {
      main: '#67e1fc',
      secondary: '#6166EC',
      gradient: ACCENT_GRADIENT,
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          padding: '1rem 2rem',
        },
      },
    },
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          fontFamily: sora.style.fontFamily,
          fontWeight: 500,
          letterSpacing: 1.2,
          fontSize: '1rem',
          padding: '0.5rem 2.5rem',
          textTransform: 'capitalize' as const,
          maxHeight: '3rem',
          borderRadius: '5px',
          backgroundColor: '#178097',
          color: '#FFF',
          transition: 'all 0.3s ease-in-out',
          whiteSpace: 'nowrap' as const,
          '&:hover': {
            backgroundColor: '#6166EC',
            borderColor: '#6166EC',
          },
        },
        loadingIndicator: {
          color: '#67e1fc',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: sora.style.fontFamily,
          fontWeight: 600,
          letterSpacing: 1.2,
          fontSize: '.9rem',
          padding: '0.5rem 2.5rem',
          textTransform: 'capitalize' as const,
          maxHeight: '3rem',
          borderRadius: '5px',
          transition: 'all 0.3s ease-in-out',
          whiteSpace: 'nowrap' as const,
          '@media (max-width: 600px)': {
            padding: '0.5rem 1rem',
          },
          '@media (max-width: 400px)': {
            padding: '0.5rem',
          },
        },
        contained: {
          color: '#ffffff !important',
          '&:hover': {
            transform: 'scale(0.94)',
            backgroundColor: '#6166EC',
          },
        },
        textSizeMedium: {
          '&:hover': {
            color: '#fff',
            transform: 'scale(0.94)',
            backgroundColor: 'unset',
          },
        },
        outlinedPrimary: {
          color: '#fff !important',
          borderColor: '#fff !important',
          backgroundColor: 'unset',
          '&:hover': {
            backgroundColor: '#272727',
            transform: 'scale(0.94)',
          },
        },
        outlinedSecondary: {
          color: '#fff',
          borderColor: '#fff !important',
          '&:hover': {
            transform: 'scale(0.94)',
            backgroundColor: 'unset',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '1em',
          color: '#fff',
          backgroundColor: 'transparent',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontFamily: sora.style.fontFamily,
          fontWeight: 500,
          letterSpacing: 1.2,
          fontSize: '1rem',
          padding: '0.5rem 0.75rem',
          color: '#fff',
          alignItems: 'center',
          display: 'flex',
          transition: 'color 300ms ease-in-out',
          '&:hover': {
            backgroundColor: 'rgba(23, 128, 151, 0.04)',
            color: '#178097',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: '#fff',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          backgroundColor: '#2E2E2E',
        },
        cellContent: {
          color: '#fff',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#fff',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          marginBottom: '1rem!important',
          borderRadius: '10px',
          '&:before': {
            display: 'none',
          },
          '&:first-of-type': {
            borderTopLeftRadius: '10px!important',
            borderTopRightRadius: '10px!important',
          },
          '&:last-of-type': {
            borderBottomLeftRadius: '10px!important',
            borderBottomRightRadius: '10px!important',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          margin: '0rem!important',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& label': {
            color: '#fff',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          color: '#fff',
        },
      },
    },
  },
};

const DarkSMATMuiTheme = createTheme(DarkSmatTheme);

export default responsiveFontSizes(DarkSMATMuiTheme);
