import axios from 'axios';
import { IS_PRODUCTION } from '@/config/Constants';
import { env } from '@/config/envs';
import { LogSeverity } from '@smatio/commons';

const logger = axios.create({
  method: 'POST',
  baseURL: env.NEXT_PUBLIC_DOMAIN,
});

export const clientLogger = ({
  message,
  pathname,
  severity,
}: {
  message:
    | string
    | {
        msg: string;
        error: Error['message'];
        stack?: Error['stack'];
      };
  pathname: string;
  severity?: LogSeverity;
}) => {
  if (!IS_PRODUCTION) {
    console.debug(`[DEBUG]: ${typeof message === 'string' ? message : JSON.stringify(message)}`);
  }
  logger({
    url: '/api/client-logger',
    data: {
      message,
      pathname,
      severity: severity || LogSeverity.INFO,
    },
  }).catch(console.error);
};
