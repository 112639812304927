import FooterHomePage from '@/components/FooterHomePage';
import NavBarHomePage from '@/components/NavBarHomePage';

const homeLayout = ({ children }) => {
  return (
    <div style={{ maxWidth: '100vw', overflowX: 'hidden' }}>
      <NavBarHomePage />
      <main>{children}</main>
      <FooterHomePage />
    </div>
  );
};

export default homeLayout;
