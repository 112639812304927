import React, { useState, useEffect } from 'react';
import { Box, Modal, Paper, Typography } from '@mui/material';

const UseConnection = () => {
  const [isConnected, setIsConnected] = useState(navigator?.onLine);

  const handleConnectionChange = () => {
    setIsConnected(navigator?.onLine);
  };

  useEffect(() => {
    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);

    return () => {
      window.removeEventListener('online', handleConnectionChange);
      window.removeEventListener('offline', handleConnectionChange);
    };
  }, []);

  return (
    <Modal
      open={!isConnected}
      aria-labelledby="offline-modal-title"
      aria-describedby="offline-modal-description"
      disableAutoFocus
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Paper
        sx={{
          backgroundColor: '#fff',
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: '80vw',
          textAlign: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'warning.light',
            p: 1,
            borderRadius: 2,
            fontWeight: 'bold',
            fontSize: '1.2rem',
            mb: 2,
          }}
          aria-hidden="true"
        >
          You are currently offline
        </Box>
        <Typography
          variant="body1"
          component="div"
          id="offline-modal-description"
          sx={{
            fontSize: '1.2rem',
          }}
        >
          Please check your internet connection and try again.
        </Typography>
      </Paper>
    </Modal>
  );
};

export default UseConnection;
