import { z } from 'zod';
import { createEnv } from '@t3-oss/env-nextjs';

export const env = createEnv({
  skipValidation: process.env.CI === 'true',
  client: {
    // FEATURE FLAGS
    NEXT_PUBLIC_FLAG_FORCE_CLEAN: z.coerce.boolean().optional(),
    NEXT_PUBLIC_FLAG_PROJECT_STATS: z.coerce.boolean().optional(),
    NEXT_PUBLIC_FLAG_PROJECT_QUESTIONS: z.coerce.boolean().optional(),
    NEXT_PUBLIC_FLAG_DIGITAL_ASSETS: z.coerce.boolean().optional(),
    NEXT_PUBLIC_FLAG_ORGANIZATIONS_AS_TEAMS: z.coerce.boolean().optional(),
    NEXT_PUBLIC_FLAG_BLUR_PUBLIC_CONTENT: z.coerce.boolean().optional(),
    NEXT_PUBLIC_FLAG_DISTRIBUTOR_NOTIFICATIONS: z.coerce.boolean().optional(),
    NEXT_PUBLIC_FLAG_TIPTAP_MEDIA: z.coerce.boolean().optional(),
    NEXT_PUBLIC_FLAG_PRESALES: z.coerce.boolean().optional(),
    NEXT_PUBLIC_AUTOLOGOUT_OFF: z.coerce.boolean().optional(),

    // Defaults
    NEXT_PUBLIC_BASE_API_URL: z.enum([
      'https://api.stage.smat.io/api',
      'https://api.beta.smat.io/api',
    ]),
    NEXT_PUBLIC_ADOBE_BASE_URL: z.enum([
      'https://api.na3.adobesign.com/api/rest/v6/',
      'https://api.eu1.adobesign.com/api/rest/v6/',
    ]),
    NEXT_PUBLIC_ADOBE_USERNAME: z.enum(['devs+stage@smat.io', 'devs@smat.io']),
    NEXT_PUBLIC_GA_MEASUREMENT_ID: z.enum(['G-N94X8EPSQJ', 'G-XJZJ0ZFDTB']),
    NEXT_PUBLIC_MINIO_BUCKET: z.string(),
    NEXT_PUBLIC_MINIO_URL: z.enum(['minio.stage.smat.io', 'cdn.beta.smat.io']),
    NEXT_PUBLIC_GA_DEBUG: z.coerce.boolean().optional(),
    NEXT_PUBLIC_ZITADEL_ISSUER: z.enum([
      'https://auth.smat.io',
      'https://smat-stage-bcxzyr.zitadel.cloud',
      'https://smat-jm3rnd.zitadel.cloud',
    ]),
    NEXT_PUBLIC_DOMAIN: z.enum([
      'http://proxyman.debug:3000',
      'http://localhost:3000',
      'http://localhost:3001',
      'https://stage.smat.io',
      'https://app.smat.io',
    ]),
    NEXT_PUBLIC_IOS_APP_STORE_URL: z.string(),
    NEXT_PUBLIC_ANDROID_APP_STORE_URL: z.string(),
    NEXT_PUBLIC_RECAPTCHA_CLIENT_KEY: z.string(),
    NEXT_PUBLIC_HJID: z.string().optional(),
    NEXT_PUBLIC_HJSV: z.string().optional(),
    NEXT_PUBLIC_BRANCH_API_KEY: z.string(),

    // External
    NEXT_PUBLIC_UAT: z.coerce.boolean().optional(),
    NEXT_PUBLIC_CI: z.coerce.boolean().optional(),
    NEXT_PUBLIC_IS_DEVELOPMENT: z.coerce.boolean().optional(),
    NEXT_PUBLIC_GITHUB_ACTIONS: z.coerce.boolean().optional(),
  },
  runtimeEnv: {
    // FEATURE FLAGS
    NEXT_PUBLIC_FLAG_FORCE_CLEAN: process.env.NEXT_PUBLIC_FLAG_FORCE_CLEAN,
    NEXT_PUBLIC_FLAG_PROJECT_STATS: process.env.NEXT_PUBLIC_FLAG_PROJECT_STATS,
    NEXT_PUBLIC_FLAG_PROJECT_QUESTIONS: process.env.NEXT_PUBLIC_FLAG_PROJECT_QUESTIONS,
    NEXT_PUBLIC_FLAG_DIGITAL_ASSETS: process.env.NEXT_PUBLIC_FLAG_DIGITAL_ASSETS,
    NEXT_PUBLIC_FLAG_ORGANIZATIONS_AS_TEAMS: process.env.NEXT_PUBLIC_FLAG_ORGANIZATIONS_AS_TEAMS,
    NEXT_PUBLIC_FLAG_BLUR_PUBLIC_CONTENT: process.env.NEXT_PUBLIC_FLAG_BLUR_PUBLIC_CONTENT,
    NEXT_PUBLIC_FLAG_DISTRIBUTOR_NOTIFICATIONS:
      process.env.NEXT_PUBLIC_FLAG_DISTRIBUTOR_NOTIFICATIONS,
    NEXT_PUBLIC_FLAG_TIPTAP_MEDIA: process.env.NEXT_PUBLIC_FLAG_TIPTAP_MEDIA,
    NEXT_PUBLIC_FLAG_PRESALES: process.env.NEXT_PUBLIC_FLAG_PRESALES,
    NEXT_PUBLIC_AUTOLOGOUT_OFF: process.env.NEXT_PUBLIC_AUTOLOGOUT_OFF,

    // Defaults
    NEXT_PUBLIC_BASE_API_URL: process.env.NEXT_PUBLIC_BASE_API_URL,
    NEXT_PUBLIC_ADOBE_BASE_URL: process.env.NEXT_PUBLIC_ADOBE_BASE_URL,
    NEXT_PUBLIC_ADOBE_USERNAME: process.env.NEXT_PUBLIC_ADOBE_USERNAME,
    NEXT_PUBLIC_GA_MEASUREMENT_ID: process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID,
    NEXT_PUBLIC_MINIO_BUCKET: process.env.NEXT_PUBLIC_MINIO_BUCKET,
    NEXT_PUBLIC_MINIO_URL: process.env.NEXT_PUBLIC_MINIO_URL,
    NEXT_PUBLIC_GA_DEBUG: process.env.NEXT_PUBLIC_GA_DEBUG,
    NEXT_PUBLIC_ZITADEL_ISSUER: process.env.NEXT_PUBLIC_ZITADEL_ISSUER,
    NEXT_PUBLIC_DOMAIN: process.env.NEXT_PUBLIC_DOMAIN,
    NEXT_PUBLIC_IOS_APP_STORE_URL: process.env.NEXT_PUBLIC_IOS_APP_STORE_URL,
    NEXT_PUBLIC_ANDROID_APP_STORE_URL: process.env.NEXT_PUBLIC_ANDROID_APP_STORE_URL,
    NEXT_PUBLIC_RECAPTCHA_CLIENT_KEY: process.env.NEXT_PUBLIC_RECAPTCHA_CLIENT_KEY,
    NEXT_PUBLIC_HJID: process.env.NEXT_PUBLIC_HJID,
    NEXT_PUBLIC_HJSV: process.env.NEXT_PUBLIC_HJSV,
    NEXT_PUBLIC_BRANCH_API_KEY: process.env.NEXT_PUBLIC_BRANCH_API_KEY,

    // External
    NEXT_PUBLIC_UAT: process.env.NEXT_PUBLIC_UAT,
    NEXT_PUBLIC_CI: process.env.NEXT_PUBLI_CI,
    NEXT_PUBLIC_IS_DEVELOPMENT: process.env.NEXT_PUBLIC_IS_DEVELOPMENT,
    NEXT_PUBLIC_GITHUB_ACTIONS: process.env.NEXT_PUBLIC_GITHUB_ACTIONS,
  },
});
