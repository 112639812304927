import React from 'react';
import Script from 'next/script';
import { env } from '@/config/envs';
import { getAssets } from '@/services/minio.service';
import { clientLogger } from '@/utils/logger';
import { LogSeverity, UtmMedium, UtmSources } from '@smatio/commons';

declare global {
  // eslint-disable-next-line no-var
  var branch: any;
}

function Branch() {
  return (
    <Script
      id="Branchio"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
      (function(b,r,a,n,c,h,_,s,d,k){if(!b[n]||!b[n]._q){for(;s<_.length;)c(h,_[s++]);d=r.createElement(a);d.async=1;d.src="https://cdn.branch.io/branch-latest.min.js";k=r.getElementsByTagName(a)[0];k.parentNode.insertBefore(d,k);b[n]=h}})(window,document,"script","branch",function(b,r){b[r]=function(){b._q.push([r,arguments])}},{_q:[],_v:1},"addListener banner closeBanner closeJourney data deepview deepviewCta first init link logout removeListener setBranchViewData setIdentity track trackCommerceEvent logEvent disableTracking getBrowserFingerprintId crossPlatformIds lastAttributedTouchData setAPIResponseCallback qrCode".split(" "), 0);
      branch.init("${env.NEXT_PUBLIC_BRANCH_API_KEY}"); 
      `,
      }}
    />
  );
}

// https://help.branch.io/developers-hub/docs/web-full-reference#link
// Branch example config:
// {
//   "randomized_bundle_token": "427469360685348303",
//   "link": "https://example.app.link?%24randomized_bundle_token=427469360685348303",
//   "session_id": "429691081177874743",
//   "data": {
//     "$canonical_identifier": "item/1503684554354.28",
//     "$canonical_url": "https://example.com/home?utm_campaign=test&deeplink=value",
//     "$desktop_url": "http://example.com/home",
//     "$randomized_bundle_token": "427469360685348303",
//     "$og_description": "My Content Description",
//     "$og_image_url": "http://lorempixel.com/200/200/",
//     "$og_title": "46D6D28E-0390-40E4-A856-BD74F34D24C8",
//     "$publicly_indexable": 1,
//     "+click_timestamp": 1503684563,
//     "+clicked_branch_link": true,
//     "+is_first_session": false,
//     "+match_guaranteed": true,
//     "custom": "blue",
//     "random": "FE848A5B-78F7-42EC-A253-9F795FE91692",
//     "added": "1503684554354.33",
//     "~campaign": "new launch",
//     "~channel": "facebook",
//     "~creation_source": 3,
//     "~feature": "sharing",
//     "~id": 429691043152332059,
//     "~referring_link": "https://example.app.link/X7OsnWv9TF",
//     "~stage": "new person",
//     "~tags": [
//       "one",
//       "two"
//     ]
//   }
// }

const defaultConfig = {
  channel: UtmSources.Smat,
  feature: UtmMedium.Clipboard,
  tags: ['webapp'],
  data: {
    $og_title: 'Smat: The Alternative Investment Network',
    $og_description:
      'Smat is a platform connecting real asset owners/sponsors with the first digital community of wealth managers.',
    $og_image_url: getAssets('v2/smat-og.png'),
  },
} as const;

/**
 * @description https://help.branch.io/using-branch/docs/creating-a-deep-link#section-configure-deep-links
 */
export async function getBranchLink(
  userId: string | number,
  config?: BranchConfigOptions
): Promise<string> {
  if (!branch) return;
  if (userId) {
    branch.setIdentity(userId, (err) => {
      if (err) {
        clientLogger({
          message: '[ERROR]: Could not set user ID in branch',
          pathname: window?.location?.href || '/branch',
          severity: LogSeverity.ERROR,
        });
      }
    });
  }
  return await new Promise((resolve) =>
    branch.link(
      {
        ...defaultConfig,
        ...config,
        ...(config?.data && {
          data: { ...defaultConfig.data, ...config.data },
        }),
      },
      (err, link) => {
        if (err) {
          clientLogger({
            message: '[ERROR]: Could not generate Branch link',
            pathname: window?.location?.href || '/branch',
            severity: LogSeverity.ERROR,
          });
          resolve('');
        }
        resolve(link);
      }
    )
  );
}

/**
 * @typedef https://help.branch.io/developers-hub/docs/web-full-reference#linkdata-callback
 */
export interface BranchConfigOptions {
  campaign?: string;
  channel: UtmSources;
  feature: UtmMedium;
  stage?: string;
  tags?: string[];
  alias?: string;
  data?: {
    $canonical_identifier?: string;
    $og_title?: string;
    $og_description?: string;
    $og_image_url?: string;
    $og_video?: string;
    $og_url?: string;
    $og_redirect?: string;

    $desktop_url?: string;
    $android_url?: string;
    $ios_url?: string;
    $ipad_url?: string;
    $fire_url?: string;
    $blackberry_url?: string;
    $windows_phone_url?: string;
    $after_click_url?: string;
    $afterclick_desktop_url?: string;

    $always_deeplink?: string;
    $deeplink_path?: string;
    [k: string]: string | number | boolean;
  };
}

export default Branch;
