import { FEATURES } from '@/utils/features';
import {
  showFormattedNames,
  InvestmentGroup,
  InvestmentType,
  ProjectListingMarketType,
  ProjectListingType,
} from '@smatio/commons';

// GLOBALS
export const ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN';
export const ROLE_WEALTH_MANAGER = 'ROLE_WEALTH_MANAGER';
export const REGISTRATION_STEP = 'registrationStep';
export const PROJECT_STEP = 'projectStep';
export const ALLOW_ACCESS_KEY = 'acss';

export const REAL_ESTATE = 'REAL_ESTATE';
export const PRIVATE_EQUITY = 'PRIVATE_EQUITY';

export const TWO_FACTOR_AUTH = 'twoFactorAuth';
export const ADOBE_ADMIN_EMAIL = 'pav@smat.io';
export const SERVICE_USER_EMAIL = 'serviceuser@smat.io';
export const PUBLIC_PATHNAMES = ['/login', '/accountCreated', '/registration', '/public'];
export const SESSION_STORAGE_KEYS = {
  idpcb: 'idpcb',
} as const;

const env = process.env;

// MARKET CAMPAINGS TABS
export const OTHER_LISTED_CAMPAIGN = 'OTHER_LISTED_CAMPAIGN';
export const MY_LISTED_CAMPAIGN = 'MY_LISTED_CAMPAIGN';

export const IS_STAGE_SERVER = !!process.env.IS_STAGE;
export const IS_PRODUCTION = process.env.NODE_ENV === 'production' && !IS_STAGE_SERVER;
export const IS_DEVELOPMENT =
  (process.env.NODE_ENV !== 'production' || !!process.env.NEXT_PUBLIC_IS_DEVELOPMENT) &&
  !IS_STAGE_SERVER;

export const IS_UAT = !!process.env.NEXT_PUBLIC_UAT;
export const IS_CI = !!process.env.NEXT_PUBLIC_CI || process.env.NODE_ENV === 'test';
export const UUID_REGEX =
  /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

// OVERVIEW PROJECT TABS
export enum PROJECT_OVERVIEW_TABS {
  Overview = 'Overview',
  Multimedia = 'Multimedia',
  Share = 'Share',
  Questions = 'Q&A',
  // owner
  Stats = 'Analytics',
  NDA = 'NDA',
  Notifications = 'Notifications',
}

export enum PROJECT_SECONDARY_TABS {
  user = 'user',
  other = 'other',
  team = 'team',
}

export enum PROJECTS_TAB {
  Current = 'current',
  Past = 'past',
  Pending = 'pending',
}
export enum MARKETCAMPAIGNS_TAB {
  Current = 'current',
  Completed = 'completed',
  Pending = 'pending',
}

export enum WALLET_DISTRUBITION {
  Overview = 'overview',
  DataRoom = 'dataRoom',
}

export enum PROJECT_PREFERENCE {
  Favorites = 'favorites',
  Excluded = 'excluded',
}

export const InvestmentGroupFilterOptions = Object.values(InvestmentGroup).map((v) => ({
  key: showFormattedNames(v),
  value: v,
  href: `${env.NEXT_PUBLIC_DOMAIN}/marketplace?owner=${PROJECT_SECONDARY_TABS.other}&investmentGroup=${v}`,
}));

export const Distribution = Object.values(ProjectListingMarketType).map((v) => ({
  key: showFormattedNames(v),
  value: v,
  href: `${env.NEXT_PUBLIC_DOMAIN}/marketplace?owner=${PROJECT_SECONDARY_TABS.other}&projectListingMarketType=${v}`,
}));

export const AssetType = Object.values(ProjectListingType)
  .map((v) => ({
    key: showFormattedNames(v),
    value: v,
    href: `${env.NEXT_PUBLIC_DOMAIN}/marketplace?owner=${PROJECT_SECONDARY_TABS.other}&projectListingType=${v}`,
  }))
  .filter(({ value }) => ![ProjectListingType.OTHER, ProjectListingType.FUND].includes(value));
export const FundingType = Object.values(InvestmentType)
  .map((v) => ({
    key: showFormattedNames(v),
    value: v,
    href: `${env.NEXT_PUBLIC_DOMAIN}/marketplace?owner=${PROJECT_SECONDARY_TABS.other}&investmentType=${v}`,
  }))
  .filter(({ value }) => ![InvestmentType.LOAN, InvestmentType.FUND].includes(value));

export const MARKETPLACE_OPTIONS = [
  {
    key: 'My Deals',
    value: PROJECT_SECONDARY_TABS.user,
    href: `${env.NEXT_PUBLIC_DOMAIN}/marketplace?owner=${PROJECT_SECONDARY_TABS.user}`,
  },
  FEATURES.OrganizationsAsTeams && {
    key: 'My Team Deals',
    value: PROJECT_SECONDARY_TABS.team,
    href: `${env.NEXT_PUBLIC_DOMAIN}/marketplace?owner=${PROJECT_SECONDARY_TABS.team}`,
  },
  { key: 'Opportunities', value: 'HOME', href: '/marketplace' },
  { key: 'Campaigns', value: 'CAMPAIGNS', href: '/marketCampaigns' },
  { key: 'Favorites', value: 'FAVORITES', href: '/projects/favorites?preference=favorites' },
  ...Distribution,
  ...FundingType,
  ...AssetType,
]
  .map(({ key, value, href }) => ({ name: key, value, href }))
  .filter(({ value }) => Boolean(value) && value !== 'FUNDING_PROJECT');

export const FUNDING_SERIES = {
  NA: 'N/A',
  SERIES_A: 'Series A',
  SERIES_B: 'Series B',
  SERIES_C: 'Series C',
  PRE_IPO: 'Pre IPO',
} as const;

export const MAX_FILE_SIZE = 10 * 1024 * 1024;
