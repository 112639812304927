import React, { ReactElement, ReactNode } from 'react';
import { useRouter } from 'next/router';
import { Subtitle, Title } from '@/components/Typography';
import { Box, Button } from '@mui/material';

interface ErrorPageProps {
  status?: 404 | 500;
  title?: string;
  subtitle?: string | ReactNode | ReactElement;
  onClick?: () => void;
}

export default function ErrorPage(props: ErrorPageProps) {
  const { isReady } = useRouter();
  const { status, title, subtitle, onClick } = props;

  if (!isReady) return null;
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '1.5rem',
        minHeight: '50vh',
        paddingTop: '1rem',
        paddingBottom: '4rem',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Title
          sx={{ fontSize: { xs: '5rem !important', md: '8rem !important' } }}
          color={'primary'}
        >
          {status || '404'}
        </Title>
        <Title
          sx={{
            fontSize: { xs: '2rem !important', md: '3.5rem !important' },
            color: 'common.gray',
          }}
        >
          {title || 'Page not found!'}
        </Title>
      </Box>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        {subtitle || (
          <>
            <Subtitle variant="subtitle1">The page you are looking for not available.</Subtitle>
            <Subtitle variant="subtitle1">If you think this is a 🐞 please get in touch.</Subtitle>
          </>
        )}
      </div>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant={'contained'}
          color={'primary'}
          onClick={() => {
            if (onClick) onClick();
            window.location.href = `${window?.location?.origin}/login`;
          }}
        >
          Go back to home
        </Button>
      </Box>
    </Box>
  );
}
