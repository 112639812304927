import React from 'react';
import { useAtomValue } from 'jotai';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { IS_CI, IS_DEVELOPMENT } from '@/config/Constants';
import { env } from '@/config/envs';
import { cookieConsentAtom } from '@/services/atoms.service';

const ENABLE = !(IS_DEVELOPMENT || IS_CI || !!env.NEXT_PUBLIC_GITHUB_ACTIONS);

declare function _gs(a: 'identify' | 'unidentify', options?: { email: string; name: string }): void;

export const identifyGoSquareUser = ({ email, name, ...rest }) => {
  if (!ENABLE || typeof _gs === 'undefined') return null;
  return _gs?.('identify', {
    email,
    name,
    ...rest,
  });
};

export const unidentifyGoSquareUser = () => {
  if (!ENABLE || typeof _gs === 'undefined') return null;
  return _gs?.('unidentify');
};

function GoSquared() {
  const cookieConsent = useAtomValue(cookieConsentAtom);
  const { pathname } = useRouter();
  if (!ENABLE || pathname === '/overview') return null;

  return (
    <Script
      id="GoSquared"
      async
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
          !(function (g, s, q, r, d) {
          r = g[r] =
          g[r] ||
          function () {
          (r.q = r.q || []).push(arguments);
          };
          d = s.createElement(q);
          q = s.getElementsByTagName(q)[0];
          d.src = '//d1l6p2sc9645hc.cloudfront.net/tracker.js';
          q.parentNode.insertBefore(d, q);
          })(window, document, 'script', '_gs');

          _gs('GSN-125016-T');
          _gs('set', 'anonymizeIP', true);
          _gs('set', 'trackLocal', true);
          _gs('set', 'useCookies', ${!!cookieConsent});
        `,
      }}
    />
  );
}

export default GoSquared;
