import { useEffect, useState, useReducer, useRef, useCallback, MutableRefObject } from 'react';
import { useRouter } from 'next/router';
import { toast } from 'sonner';
import { ASSET_CLASS_OPTIONS, CATEGORY_OPTIONS } from '@/containers/Settings/InvestmentProfile';
import { callPreferencesCrmPerson } from '@/services/api.service';
import { fetcher } from '@/services/fetch.service';
import useMuiMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/system';
import { CONTRACT_SIGNATURE_STATUS } from '@smatio/commons';

interface Size {
  height: number;
  width: number;
}
function useElementSize(ref: MutableRefObject<HTMLElement>): Size {
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  });

  const handleSize = useCallback(() => {
    setSize({
      width: ref.current?.offsetWidth ?? 0,
      height: ref.current?.offsetWidth ?? 0,
    });
  }, []);

  useEffect(() => {
    if (ref.current) {
      handleSize();
    }
  }, [ref.current, ref?.current?.offsetWidth]);

  return size;
}

function useKeyPress(targetKey) {
  const [keyPressed, setKeyPressed] = useState(false);
  function downHandler({ key }) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }
  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []);
  return keyPressed;
}

const actionTypes = {
  toggle: 'TOGGLE',
  on: 'ON',
  off: 'OFF',
} as const;

function toggleReducer(state, action) {
  switch (action.type) {
    case actionTypes.toggle: {
      return { on: action.payload ?? !state.on };
    }
    case actionTypes.on: {
      return { on: true };
    }
    case actionTypes.off: {
      return { on: false };
    }
    default: {
      throw new Error(`Unhandled type: ${action.type}`);
    }
  }
}

function useToggle({ reducer = toggleReducer, initialState } = { initialState: false }) {
  const [{ on }, dispatch] = useReducer(reducer, { on: initialState });

  const toggle = (state?: boolean) => dispatch({ type: actionTypes.toggle, payload: state });
  const setOn = () => dispatch({ type: actionTypes.on });
  const setOff = () => dispatch({ type: actionTypes.off });

  return { on, toggle, setOn, setOff, toggleReducer };
}

const useMediaQuery = (
  breakpoint: 'xs' | 'sm' | 'md' | 'lg' | 'xl',
  direction: 'down' | 'up' = 'down'
) => {
  const { breakpoints } = useTheme();
  return useMuiMediaQuery(breakpoints?.[String(direction)]?.(breakpoint));
};

function usePrefersDarkMode() {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => {
      setDarkMode(e.matches ? true : false);
    };
    mediaQuery.addListener(handleChange);
    handleChange(mediaQuery);
    return () => {
      mediaQuery.removeListener(handleChange);
    };
  }, []);
  return !!darkMode;
}

function useDarkMode() {
  const [enabledState, setEnabledState] = useLocalStorage(
    'dark-mode-enabled',
    typeof window !== 'undefined' &&
      window?.matchMedia &&
      window?.matchMedia('(prefers-color-scheme: dark)')?.matches
  );
  const prefersDarkMode = usePrefersDarkMode();
  const enabled = typeof enabledState !== 'undefined' ? enabledState : prefersDarkMode;

  useEffect(() => {
    if (enabled) {
      document?.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#2E2E2E');
    } else {
      document?.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#f0f0f0');
    }
  }, [enabled]);

  return [enabled, setEnabledState];
}

function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.debug(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.debug(error);
    }
  };
  return [storedValue, setValue];
}

function useOnScreen(ref, rootMargin = '0px', config = {}) {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
        ...config,
      }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  });
  return isIntersecting;
}

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

const usePreviousRoute = () => {
  const { asPath } = useRouter();

  const ref = useRef<string | null>(null);

  useEffect(() => {
    ref.current = asPath;
  }, [asPath]);

  return ref.current;
};

/**
 * Component to read and remove utm_fields from the URL
 * @abstract Should not be used at top level _app, should be user inside containers and GA should be running
 * @link {docs} https://github.com/smatio/webapp/issues/355
 */
const useUTM = () => {
  const { replace, query } = useRouter();

  useEffect(() => {
    if (!Object.keys(query)?.some((k) => !k.includes('utm_') || !k.includes('branch'))) {
      return;
    }

    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      utm_source,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      utm_medium,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      utm_content,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      utm_term,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      utm_name,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      utm_campaign,
      _branch_match_id,
      _branch_referrer,
      ...rest
    } = query;

    replace(
      {
        query: { ...rest },
      },
      undefined,
      { shallow: true }
    );
  }, []);

  return null;
};

const useBeforeUnload = (m?: string) => {
  useEffect(() => {
    if (typeof window === 'undefined') return;
    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      const message = m || 'Are you sure you want to close?';
      toast.info(m || 'Please confirm that agreement is signed before closing the page');
      e.returnValue = message; //Gecko + IE
      return message;
    };
    window.addEventListener('beforeunload', onBeforeUnload);
    return function cleanupListener() {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, []);
  return null;
};

const useQueryErrorToast = () => {
  const {
    query: { error, ...rest },
    replace,
  } = useRouter();

  useEffect(() => {
    if (error) {
      toast.error(error);
      replace(
        {
          query: { ...rest },
        },
        undefined,
        { shallow: true }
      );
    }
  }, [error]);

  return null;
};

const useHtmlElementClassObserver = (element: HTMLElement) => {
  const [classNames, setClassNames] = useState(Array.from(element.classList));

  useEffect(() => {
    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          setClassNames(Array.from(element.classList));
        }
      });
    });

    observer.observe(element, { attributes: true });

    return () => {
      observer.disconnect();
    };
  }, [element]);

  return classNames;
};

export function useDebouncedValue(value: any, delay = 250) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

const useAdobeContract = ({ fetchOnMount = false }: { fetchOnMount?: boolean } = {}) => {
  const [contract, setContract] = useState<{
    url: string;
    status: CONTRACT_SIGNATURE_STATUS | null;
  }>({ url: '', status: null });
  const counter = useRef(0);
  const { on, toggle } = useToggle({ initialState: fetchOnMount ? true : false });

  useEffect(() => {
    if (counter?.current === 0 && fetchOnMount) {
      (async () => {
        const res = await fetcher<{
          url: string;
          message?: string;
          status: CONTRACT_SIGNATURE_STATUS;
        }>('/api/contract', { baseURL: '/' });
        setContract((pv) => ({
          ...pv,
          url: res.url,
          ...(res.status ? { status: res.status } : {}),
        }));
        toggle(false);
        counter.current++;
      })();
    }

    return () => {
      counter.current = 0;
    };
  }, []);

  return { contract, setContract, loading: on };
};

const useLoadPreferences = (email, setValue) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await callPreferencesCrmPerson(email);
        if (response && response.data) {
          const preferences = response.data.data;

          setValue('interestedInPrivateMarkets', preferences?.InterestedInPrivateMarkets, {
            shouldDirty: true,
          });
          setValue('interestedInCommunityNews', preferences?.InterestedInCommunityNews, {
            shouldDirty: true,
          });
          setValue(
            'interestedInPrivateMarketEvents',
            preferences?.InterestedInPrivateMarketEvents,
            { shouldDirty: true }
          );
          setValue('interestedInDigitalAssetEvents', preferences?.InterestedInDigitalAssetEvents, {
            shouldDirty: true,
          });
          const assetClassValues = preferences?.AssetClassInterest?.split(',').map(
            (value: string) => {
              const option = ASSET_CLASS_OPTIONS.find(
                (option) => option.value.toString() === value.trim()
              );
              return option ? { value: option.value, label: option.label } : null;
            }
          );

          setValue('assetClassInterest', assetClassValues, { shouldDirty: true });

          const categoryValues = preferences?.CategoryOfInvestmentInterest?.split(',').map(
            (value: string) => {
              const option = CATEGORY_OPTIONS.find(
                (option) => option.value.toString() === value.trim()
              );
              return option ? { value: option.value, label: option.label } : null;
            }
          );

          setValue('categoryOfInvestmentInterest', categoryValues, { shouldDirty: true });
        }
      } catch (error) {
        throw new Error(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (email) {
      fetchData();
    }
  }, [email, setValue]);

  return isLoading;
};

export {
  useHtmlElementClassObserver,
  useQueryErrorToast,
  useBeforeUnload,
  useKeyPress,
  useAdobeContract,
  useToggle,
  useMediaQuery,
  useDarkMode,
  useOnScreen,
  useWindowSize,
  usePreviousRoute,
  useElementSize,
  useUTM,
  useLoadPreferences,
};
