import { getSession } from 'next-auth/react';
import { isProjectCreatedByCurrentUser } from '@/containers/Project/projectUtil';
import { SessionUser } from '@/types/next-auth';
import { FEATURES } from '@/utils/features';
import {
  Company,
  ProjectInputGroup,
  MasterProject,
  UserRoles,
  UserStatus,
  ProjectData,
  isMasterProjectTypeGuard,
} from '@smatio/commons';

export const getLoginUserId = async () => {
  const session = await getSession();
  return session?.user?.sub;
};

/**
 * Check if user has ROLE_WEALTH_MANAGER assigned to him.
 * @param {UserRoles[] | UserRoles} UserRoles - the role or role[] of the user
 */
export const isWealthManager = (userRoles: UserRoles[] | UserRoles): boolean => {
  if (!userRoles) return false;
  if (Array.isArray(userRoles)) {
    return !!userRoles.includes(UserRoles.ROLE_WEALTH_MANAGER);
  }
  return userRoles === UserRoles.ROLE_WEALTH_MANAGER;
};

/**
 * Check if user has ROLE_INVESTOR assigned to him.
 * @param {UserRoles[] | UserRoles} UserRoles - the role or role[] of the user
 */
export const isInvestorUser = (userRoles: UserRoles[] | UserRoles): boolean => {
  if (!userRoles) return false;
  if (Array.isArray(userRoles)) {
    return !!userRoles.includes(UserRoles.ROLE_INVESTOR);
  }
  return userRoles === UserRoles.ROLE_INVESTOR;
};

/**
 * Check if user has completed registration form but not if approved by admin.
 * @param {SessionUser} SessionUser - the session of the user
 */
export const isFullyRegisteredUser = (user: SessionUser): boolean => {
  if (!!user?.hasDocumentInfo) {
    return true;
  }
  return false;
};

/**
 * Check if user has ROLE_DISTRIBUTOR assigned to him.
 * @param {UserRoles[] | UserRoles} UserRoles - the role or role[] of the user
 */
export const isDistributor = (userRoles: UserRoles[] | UserRoles): boolean => {
  if (!userRoles) return false;
  if (Array.isArray(userRoles)) {
    return !!userRoles.includes(UserRoles.ROLE_DISTRIBUTOR);
  }
  return userRoles === UserRoles.ROLE_DISTRIBUTOR;
};

/**
 * Check if user has ROLE_SUPER_ADMIN assigned to him.
 * @param {UserRoles[] | UserRoles} UserRoles - the role or role[] of the user
 */
export const isAdminUser = (userRoles: UserRoles[] | UserRoles): boolean => {
  if (!userRoles) return false;
  if (Array.isArray(userRoles)) {
    return !!userRoles.includes(UserRoles.ROLE_SUPER_ADMIN);
  }
  return userRoles === UserRoles.ROLE_SUPER_ADMIN;
};

/**
 * Check if user registration has been approved by admin
 * @param {UserStatus} UserStatus - the UserStatus from the user object/session
 */
export const isApprovedUser = (status: UserStatus) => {
  if (!status) return false;
  return status === UserStatus.APPROVED;
};

export const isPendingUser = (status: UserStatus) => {
  if (!status) return false;
  return status === UserStatus.PENDING;
};

/**
 * Check if both sides have signed the contract
 * @param {SessionUser} User - the user that comes from the session
 */
export const isContractFullySigned = (user: SessionUser) => {
  if (!user) return false;
  return !!user.isAdminSignAgreement && !!user.isContractSigned;
};

export const isUnderRegistrationUser = (status: UserStatus) => {
  if (!status) return false;
  return UserStatus.UNDER_REGISTRATION === status;
};

/**
 * Check if only one side of the contract has been signed either admin or user
 * @param {SessionUser} User - the user that comes from the session
 */
export const isContractPartiallySigned = (user: SessionUser) => {
  //return true to avoid due to a contract being partially sign is a negative thing
  if (!user) return true;
  return !user.isAdminSignAgreement || !user.isContractSigned;
};

export const isNewUser = (user: SessionUser) => {
  return (
    !user.hasCompanyInfo &&
    !user.hasDocumentInfo &&
    !user.hasPersonalInfo &&
    user.status === UserStatus.UNDER_REGISTRATION &&
    (!user.authorities?.length || user.authorities?.[0] === UserRoles.ROLE_NO_ROLE)
  );
};

export const addStepPrefix = (step: ProjectInputGroup, str: string): string => {
  return `${step}.${str}`;
};

export const isPendingEmailPhoneVerification = (user: SessionUser) => {
  return (
    (!user?.emailVerified || !user?.phoneVerified) &&
    !(user?.isContractSigned && user?.status === UserStatus.APPROVED)
  );
};

export const isCompanyCreatorUser = (user: SessionUser, company: Company) => {
  return user?.id === company?.companyCreatedUserId;
};

export const isCompanyDataCompleted = (company: Company) => {
  if (!company) return false;
  return (
    !!company.companyRegisterId &&
    !!company.legalName &&
    !!company.country &&
    !!company.phoneNumber &&
    !!company.email &&
    !!company.address?.city &&
    !!company.address?.street &&
    !!company.address?.zipCode &&
    !!company.address?.country
  );
};

/**
 * Used to check if user belongs to same organization that owns the project.
 * @param user
 * @param project {ProjectData} if MasterProject is not passed as ProjectData, then it will return false
 * @returns
 */
export const areUserAndProjectSameCompany = (user: SessionUser, project: ProjectData) => {
  if (!FEATURES.OrganizationsAsTeams || !isMasterProjectTypeGuard(project)) {
    return false;
  }
  return (
    (!!user?.organization?.uuid && user?.organization?.uuid === project?.ownerCompanyId) ||
    project?.isEditAccessEnabled
  );
};

/**
 * Used to check if user is allowed to view private project
 * @param user
 * @param project
 * @returns
 */
export const canViewPrivateProject = (user: SessionUser, project: MasterProject) => {
  if (!project?.isPrivateProject) {
    return true;
  }
  return (
    project.isPrivateProject &&
    Boolean(isAdminUser(user.authorities) || areUserAndProjectSameCompany(user, project))
  );
};

/**
 * Used to check if user is allowed to edit project
 * @param user SessionUser
 * @param project ProjectData
 * @returns
 */
export const canEditCurrentProject = (user: SessionUser, project: ProjectData) => {
  return Boolean(
    isProjectCreatedByCurrentUser(project?.createdBy, user) ||
      areUserAndProjectSameCompany(user, project)
  );
};

/**
 * Helper function to check if the deal is visible & editable by lister user team
 * @param project ProjectData
 * @returns
 */
export const isTeamProject = (project: ProjectData) => {
  if (!project) return false;
  return !!isMasterProjectTypeGuard(project) && !!project?.ownerCompanyId;
};
