import axios, { AxiosError } from 'axios';
import { clsx, type ClassValue } from 'clsx';
import crypto from 'crypto';
import { SubmitErrorHandler } from 'react-hook-form';
import { toast, ExternalToast } from 'sonner';
import { twMerge } from 'tailwind-merge';
import { inspect } from 'util';
import { v4 } from 'uuid';
import { ZodError } from 'zod';
import { IS_DEVELOPMENT } from '@/config/Constants';
import { env } from '@/config/envs';
import { InvestmentGroup, InvestmentType } from '@smatio/commons';
import { Currency, LogSeverity, OrderDetail, OrderType } from '@smatio/commons';
import { HttpStatus } from '../enums/statusCode';

export const scrollToTop = () => window?.scrollTo({ top: 0, behavior: 'smooth' });

export const checkApiErrorResponse = (status) => {
  if (
    status === HttpStatus.CONFLICT ||
    status === HttpStatus.NOT_ACCEPTABLE ||
    status === HttpStatus.INTERNAL_SERVER_ERROR ||
    status === HttpStatus.NOT_FOUND ||
    status === HttpStatus.UNPROCESSABLE_ENTITY
  ) {
    return true;
  }
  return false;
};

export const checkApiSuccessResponse = (status) => {
  if (!status) return false;
  if (String(status)[0] === '2') return true;
  return false;
};

export const GET_UNHANDLED_ERROR = 'Oops something went wrong';

export const convertCapitalizedCase = (value: string): string => {
  if (!value) return '';
  return value
    .split(' ')
    .map((s) => s.slice(0, 1).toUpperCase() + s.slice(1)?.toLowerCase())
    .join(' ');
};

export function concatMockOders<T>(
  arr: OrderDetail[],
  options: {
    customMock?: T[];
    order?: 'end' | 'beginning';
    type?: OrderType;
  } = {
    order: 'end',
  }
) {
  const mockOrders =
    options.customMock ||
    MOCK_ORDERS.filter(({ orderType }) => (!!options?.type ? orderType === options.type : true));

  return options.order === 'beginning' ? [...mockOrders, ...arr] : [...arr, ...mockOrders];
}

const MOCK_ORDERS: Partial<OrderDetail>[] = [
  {
    uuid: v4(),
    projectName: 'Winston Luxury Real Estate',
    investmentType: InvestmentType.DEBT,
    orderPoster: 'Robert Fox',
    pricePerUnit: 599.99,
    availableUnit: 0,
    validUntil: '2024-03-12T00:00:00Z',
    orderType: OrderType.Sell,
    defaultCurrency: Currency.USD,
    isMock: true,
  },
  {
    uuid: v4(),
    projectName: 'Admiral Luxury Real Estate',
    investmentType: InvestmentType.DEBT,
    orderPoster: 'Robert Fox',
    pricePerUnit: 10000,
    availableUnit: 0,
    validUntil: '2024-03-12T00:00:00Z',
    orderType: OrderType.Sell,
    defaultCurrency: Currency.USD,
  },
  {
    uuid: v4(),
    projectName: 'Keylodge Partners',
    investmentType: InvestmentType.DEBT,
    orderPoster: 'Mikkel Peters',
    pricePerUnit: 20000,
    availableUnit: 0,
    validUntil: null,
    orderType: OrderType.Sell,
    defaultCurrency: Currency.USD,
  },
  {
    uuid: v4(),
    projectName: 'Winston Luxury Real Estate',
    investmentType: InvestmentType.DEBT,
    orderPoster: 'Mikkel Peters',
    pricePerUnit: 999,
    availableUnit: 0,
    validUntil: null,
    orderType: OrderType.Sell,
    defaultCurrency: Currency.USD,
  },
  {
    uuid: v4(),
    projectUuid: '8cd04915-5616-4bc4-bcd8-2e6b04b695a8',
    projectName: 'Limitless Assets',
    investmentType: InvestmentType.FUND,
    orderPoster: 'Esther Howard',
    pricePerUnit: 999,
    availableUnit: 0,
    validUntil: null,
    orderType: OrderType.Sell,
    defaultCurrency: Currency.USD,
  },
  {
    uuid: v4(),
    projectName: 'Keylodge Partners',
    investmentType: InvestmentType.DEBT,
    orderPoster: 'Mikkel Peters',
    pricePerUnit: 342.23,
    availableUnit: 0,
    validUntil: null,
    orderType: OrderType.Sell,
    defaultCurrency: Currency.USD,
  },
  {
    uuid: v4(),
    projectName: 'Paris Raspail Real Estate',
    investmentType: InvestmentType.DEBT,
    orderPoster: 'Esther Howard',
    pricePerUnit: 16225.15,
    availableUnit: 0,
    validUntil: '2024-01-01T00:00:00Z',
    orderType: OrderType.Buy,
    defaultCurrency: Currency.USD,
    isMock: true,
  },
  {
    uuid: v4(),
    projectName: 'Geneva Lake II',
    investmentType: InvestmentType.DEBT,
    orderPoster: 'Robert Fox',
    pricePerUnit: 125000,
    availableUnit: 0,
    validUntil: '2024-12-31T00:00:00Z',
    orderType: OrderType.Buy,
    defaultCurrency: Currency.USD,
  },
  {
    uuid: v4(),
    projectName: 'Paris Sorbonne',
    investmentType: InvestmentType.FUND,
    orderPoster: 'Mikkel Peters',
    pricePerUnit: 5550,
    availableUnit: 0,
    validUntil: null,
    orderType: OrderType.Buy,
    defaultCurrency: Currency.USD,
  },
].map((elem) => ({
  ...elem,
  isMock: true,
  projectUuid: '',
  isinNumber: '',
  clientId: '',
  bankListOfCustomer: [],
  bankDetail: {
    uuid: '',
    iban: '',
    bankName: '',
    accountManagerName: '',
    accountManagerEmail: '',
  },
  clientName: '',
  orderPosterUsername: '',
}));

export const convertCamelToSnakeCase = (value: string): string => {
  if (!value) return '';
  return value.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};

/**
 * Prevents a number overflow from excedeen min or max JS numbers
 * @param num Number
 * @returns Number
 */
export const safeNumber = (num: number): number => {
  if (num >= Number.MAX_SAFE_INTEGER) return 1;
  if (num <= Number.MIN_SAFE_INTEGER) return 1;
  return num;
};

export const parseQueryObjectToURL = (
  path = '/',
  query: { [key: string]: string | string[] } = {},
  initial = {}
): URL => {
  const qParams = { ...initial, ...query };
  const search = new URLSearchParams(qParams);
  const url = new URL(`${path}?${search}`, env.NEXT_PUBLIC_DOMAIN);
  return url;
};

/**
 * Simple function to create a sha1 hash from an object concatenating all values as strings wrapped in ""
 * @param obj Object which values we want to hash
 * @returns
 */
export const simpleHash = (obj: object): string => {
  let unhashedString = '';
  for (const key in obj) {
    unhashedString += JSON.stringify(obj[key]);
  }
  return crypto.createHash('sha1').update(unhashedString).digest('hex');
};

export function getRandomOccurrences(inputArray: any[], outputLength = 3): any[] {
  if (!inputArray || inputArray.length === 0) return [];
  const occurrences = Math.min(inputArray.length, outputLength); // Get at most 3 occurrences

  // Generate an array of indices from 0 to inputArray.length - 1
  const indices = Array.from({ length: inputArray.length }, (_, index) => index);

  // Shuffle the array of indices randomly
  for (let i = indices.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [indices[i], indices[j]] = [indices[j], indices[i]];
  }

  // Take the first `occurrences` indices and sort them randomly
  const selectedIndices = indices.slice(0, occurrences);
  selectedIndices.sort(() => Math.random() - 0.5);

  // Create a new array with the randomly selected occurrences
  const randomOccurrences = selectedIndices.map((index) => inputArray[index]);

  return randomOccurrences;
}

export const clientLog = async ({
  message,
  severity = LogSeverity.ERROR,
  location,
}: {
  message: string;
  severity?: LogSeverity;
  location?: string;
}) => {
  return axios('/api/client-logger', {
    baseURL: env.NEXT_PUBLIC_DOMAIN,
    method: 'POST',
    data: {
      message,
      pathname: location ?? window?.location?.href,
      severity,
    },
  }).catch(console.error);
};

export const fireSuccessToast = (message: string, config: { loading?: number | string } = {}) => {
  const { loading, ...rest } = config;
  if (loading) {
    toast.dismiss(loading);
  }
  toast.success(message, rest);
};

/**
 * This function fires an error toast with a message.
 * @param error en error string message
 * @param location the pathname or current place of the application where this error was triggered
 * @param options ToastOptions, severity and/or loading toast reference
 */
export const fireErrorToast = async (
  error: string | Error | unknown,
  location?: string,
  options?: ExternalToast & { severity?: LogSeverity; loading?: number | string; mute?: boolean }
): Promise<void> => {
  let message: string;

  // Do not show or report AbortController errors
  if (!!(error instanceof DOMException && error.name == 'AbortError')) {
    return;
  }
  if (error instanceof Error) {
    message = error.message || GET_UNHANDLED_ERROR;
  }

  if (error instanceof ZodError) {
    message = error.issues.map((e) => e.message).join('\n');
  }

  if (error instanceof AxiosError) {
    message = error.response?.data?.message ?? error.message;
  }

  if (error && typeof error === 'object' && !message) {
    message = JSON.stringify(error);
  }

  if (typeof error === 'string') {
    message = error;
  }

  clientLog({
    message,
    severity: options?.severity ?? LogSeverity.ERROR,
    location,
  });

  if (options?.loading) {
    toast.dismiss(options.loading);
  }
  if (options?.mute) {
    return;
  }
  toast.error(message, options);
};

export const onInvalid: SubmitErrorHandler<unknown> = (errors): void => {
  if (IS_DEVELOPMENT) {
    console.debug(`[DEBUG]: ${inspect(errors)}`);
  }
  fireErrorToast(
    Object.values(errors)
      .map((e) => e.message)
      .join('\n') ?? JSON.stringify(errors),
    window?.location?.href
  );
};

export function capitalizeFirstLetter([first = '', ...rest]: string) {
  return [first.toUpperCase(), ...rest].join('');
}
export function lowercaseFirstLetter([first = '', ...rest]: string) {
  return [first.toLowerCase(), ...rest].join('');
}

export function maskPhoneNumber(phoneNumber: string) {
  // Check if the phone number is too short to format
  if (!phoneNumber || phoneNumber.length < 5) {
    return phoneNumber;
  }

  // Determine how many characters to show at the beginning and end
  const showChars = phoneNumber.length > 8 ? 3 : 2;

  // Extract the beginning and end parts of the phone number
  const start = phoneNumber.slice(0, showChars);
  const end = phoneNumber.slice(-showChars);

  // Calculate the number of asterisks to add
  const asterisksLength = phoneNumber.length - showChars * 2;
  const asterisks = '*'.repeat(asterisksLength);

  // Return the formatted phone number
  return `${start}${asterisks}${end}`;
}

export const generateMockCards = (num = 4, config = { status: 'mock' }) => {
  const names = [
    'random_image.jpg',
    'random_image2.jpg',
    'random_image3.jpg',
    'random_image4.jpg',
    'random_image5.png',
    'random_image6.png',
  ];
  const publicNames = [
    'https://cdn.beta.smat.io/assets/v2/header.png',
    'https://cdn.beta.smat.io/assets/v2/promoapps.jpeg',
  ];
  const randomNames = ({ names }: { names: string[] }) => {
    return names[getPseudoRandomNumber(names.length) - 1];
  };

  const createMockCard = () => {
    const projectPicture = randomNames({ names });
    return {
      investmentGroup: InvestmentGroup.DIRECT_INVESTMENT,
      projectListingMarketType: 'PRIMARY',
      fundRaisingExpireDate: '127 days',
      projectName: 'What is Lorem Ipsum?',
      defaultCurrency: 'USD',
      investmentType: 'EQUITY',
      projectListingType: 'PRIVATE_EQUITY',
      status: config?.status || 'mock',
      hardCap: 10000000,
      ...(config?.status === 'mock'
        ? {
            projectSupportingDocument: {
              projectPicture: projectPicture || null,
            },
          }
        : {
            projectName: 'List your deal @ Smat.io',
            projectDescription:
              'We streamline the access to professional investors. Our mission is to help asset and fund managers distribute and promote their financial products to our community of professional investors',
            masterProjectId: crypto.randomUUID(),
            projectPicture: {
              projectPicture: randomNames({ names: publicNames }) || null,
            },
            interestPa: '',
            expectedIrr: '',
          }),
    };
  };

  const mockCard = [];
  for (let i = 0; i < num; i++) {
    mockCard.push(createMockCard());
  }
  return mockCard;
};

export function toSnakeCase(str): string {
  if (!str) return '';
  return str.toLowerCase().replace(/\s+/g, '_');
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

/**
 * @param str
 * @returns normalized string without underscores and in lowercase.
 */
export function normalizeString(str: string, regex = /_/g): string {
  return str?.replace(regex, '')?.trim()?.toLowerCase();
}

export function isHttpAbsoluteUrl(url: string): boolean {
  const pattern = /^https?:\/\//i;
  return pattern.test(url);
}

/**
 * Used to retrieve key from teh useSWRCache object
 * @param iterator derived by calling cache.keys() on useSWRConfig cache object
 * @param condition an string condition to match to find the required key to retrieve
 * @returns string | null
 */
export const getSWRCacheKey = (
  iterator: IterableIterator<string>,
  condition: string
): string | null => {
  for (let n = iterator.next(); !n.done; n = iterator.next()) {
    if (n.value.includes(condition)) {
      return n.value;
    }
  }
  return null;
};

export const getPseudoRandomNumber = (() => {
  const available = {} as Record<number, boolean>;
  return (length = 5): number | undefined => {
    if (Object.keys(available).length === 0) {
      for (let i = 1; i <= length; i++) {
        available[i] = true;
      }
    }
    const keys = Object.keys(available).map(Number);
    if (keys.length === 0) return undefined; // No more numbers available
    const randomIndex = Math.floor(Math.random() * keys.length);
    const selected = keys[randomIndex];

    delete available[selected];
    return selected;
  };
})();

export function isLoadedInsideIframe() {
  if (typeof window === 'undefined') return false;
  return window.location !== window.parent.location ? true : false;
}

export function openCenteredPopupWindow(url: string, title = 'popup', w: number, h: number) {
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  return window.open(
    url,
    title,
    `toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,copyhistory=no,width=${w},height=${h},left=${left},top=${top}`
  );
}

export const getErrorMessage = (code: string | number, def?: string): string => {
  switch (code) {
    case 'Errors.User.NotActive (SESSION-Gj4ko)':
      return 'Your account is not active. Please contact support@smat.io';
    case 'Password is invalid (COMMAND-3M0fs)':
      return 'Username or password invalid';
    case 'User could not be found (QUERY-Dfbg2)':
      return 'User not found';
    default:
      return def ?? 'An error occurred. Please try again later.';
  }
};
