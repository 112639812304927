import { InvestmentType } from '@smatio/commons';

export const DRAFT_URL = '/project';
export const PUBLIC_DEAL_LISTING_COOKIE = 'SMAT_PDLK';
export const LS_ROLE_INVITATION_KEY = 'SMAT_RIK';
export const BIG_FILE_SIZE = 5 * 1000 * 1000;
export const REQUIRED_DOCS = [
  'summaryBusinessDeck',
  'teaserInformationMemorandum',
  'termSheet',
  'projectPicture',
];

export const REQUIRED_DOCS_FUND = [
  'projectPicture',
  'summaryBusinessDeck',
  'certificateOfIncorporation',
  'byLaws',
  'shareHolderAgreement',
  'termSheet',
];

export const REQUIRED_DOCS_BY_INVEST_TYPE = {
  [InvestmentType.DEBT]: REQUIRED_DOCS,
  [InvestmentType.EQUITY]: REQUIRED_DOCS,
  [InvestmentType.FUND]: REQUIRED_DOCS_FUND,
};
