import { useContext } from 'react';
import { useSetAtom } from 'jotai';
import { useSession } from 'next-auth/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ThemeContext } from '@/pages/_app';
import { toggleLoginModal } from '@/services/atoms.service';
import { sora } from '@/themes/customizations';
import { useMediaQuery, useToggle } from '@/utils/hooks';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Box,
  Button,
  Container,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { SmatLogo } from '../Logos/Logos';

const NavBarHomePage = () => {
  const { pathname } = useRouter();
  const { toggle, on: openMenu } = useToggle();
  const setLoginModal = useSetAtom(toggleLoginModal);

  const isPublicPage = pathname.includes('/public/show-project-detail') || pathname.includes('404');
  const [isDarkMode] = useContext(ThemeContext);
  const isMd = useMediaQuery('md');
  const session = useSession();

  const LoginButton = () => {
    if (session?.status === 'authenticated') {
      return (
        <Link passHref href={'/'} legacyBehavior>
          <Button variant={'contained'}>Launch App</Button>
        </Link>
      );
    }
    return (
      <Button onClick={() => setLoginModal(true)} variant={'contained'}>
        Sign in
      </Button>
    );
  };

  return (
    <Box component={'header'}>
      <Container
        component={'nav'}
        sx={{
          display: 'flex',
          py: '1rem',
          alignItems: 'center',
          position: 'relative',
          zIndex: 1500,
          bgcolor: isMd ? 'common.bgCard' : 'unset',
        }}
      >
        <SmatLogo
          black={!isDarkMode}
          sx={{
            height: '48px',
            width: '160px',
            cursor: 'pointer',
            display: { xs: 'initial', lg: 'initial' },
            marginRight: '2rem',
            flexShrink: 0,
          }}
          href="https://smat.io"
        />

        {isMd ? (
          <>
            <Box sx={{ flex: 1 }} />
            <IconButton onClick={() => toggle()}>
              {openMenu ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
            <MobileDrawer open={openMenu} onClose={() => toggle(false)} />
          </>
        ) : (
          <>
            <List
              sx={{
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                '& > *': { flex: 1 },
                px: '1rem',
              }}
            >
              {LINKS.map(({ name, href, nonPublic }) => {
                if (nonPublic && isPublicPage) return null;
                return (
                  <ListItemButton
                    alignItems="center"
                    href={href}
                    key={name}
                    sx={{
                      color: ({ palette }) => `${palette.text.primary} !important`,
                      justifyContent: 'center',
                      fontFamily: sora.style.fontFamily,
                      borderRadius: '4px',
                    }}
                  >
                    {name}
                  </ListItemButton>
                );
              })}
            </List>
            <LoginButton />
          </>
        )}
      </Container>
    </Box>
  );
};

const MobileDrawer = ({ open, onClose }) => {
  return (
    <Drawer anchor={'top'} open={open} onClose={onClose} sx={{ zIndex: 10 }}>
      <List sx={{ marginTop: '80px' }}>
        {LINKS.map(({ href, name }) => (
          <ListItem key={name} disablePadding>
            <Link passHref href={href} legacyBehavior>
              <ListItemButton LinkComponent={'a'} onClick={onClose}>
                <ListItemText
                  primary={name}
                  sx={{ textAlign: 'center' }}
                  primaryTypographyProps={{
                    fontWeight: 'bold',
                    sx: {
                      '&:hover': { color: 'primary.main' },
                    },
                  }}
                />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

const LINKS = [
  {
    name: 'Home',
    href: '/',
    nonPublic: true,
  },
  {
    name: 'Private Markets',
    href: 'https://www.smat.io',
  },
  {
    name: 'Digital Assets',
    href: 'https://token.smat.io',
  },
  {
    name: 'About',
    href: 'https://www.smat.io/about-us',
  },
  {
    name: 'Contact us',
    href: 'https://token.smat.io/contactus',
  },
];

export default NavBarHomePage;
