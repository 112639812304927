import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useAtomValue } from 'jotai';
import { NextApiRequest } from 'next';
import { getToken } from 'next-auth/jwt';
import { getSession, signOut } from 'next-auth/react';
import useSWR from 'swr';
import useSWRImmutable from 'swr/immutable';
import useSWRInfinite, { SWRInfiniteKeyLoader } from 'swr/infinite';
import { ZodTypeAny, z } from 'zod';
import { env } from '@/config/envs';
import { GetErrorResult } from '@/types/interfaces';
import { GET_UNHANDLED_ERROR } from '@/utils';
import { clientLogger } from '@/utils/logger';
import { until } from '@open-draft/until';
import { LogSeverity } from '@smatio/commons';
import { APIErrorType } from './api.service';
import { tokenAtom } from './atoms.service';

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  async function (error: AxiosError) {
    // @ts-expect-error wontfix
    const status = String(error.response.status || error.response?.data?.status || 500);

    clientLogger({
      message: {
        msg: error?.message,
        error: JSON.stringify(error),
        stack: error.stack,
      },
      severity: status.startsWith('5') ? LogSeverity.ERROR : LogSeverity.INFO,
      pathname: window?.location?.href ?? '',
    });

    if (error.message === 'Network Error' || ['401', '500'].includes(status)) {
      // If unauth error, then signout the user and return the error to be handled by the fetcher
      const params = new URLSearchParams(
        {
          '401': { error: 'Session expired, please log in again' },
          '500': { error: 'Internal Server Error' },
        }[status]
      );
      await signOut({
        callbackUrl: `${env.NEXT_PUBLIC_DOMAIN}/login?` + params.toString(),
      });
    }
    return error;
  }
);

export const API_BASE_URL = env.NEXT_PUBLIC_BASE_API_URL;
export interface APIPayload {
  status: number;
  message: string;
  error?: string;
  [key: string]: any; // TODO: try to narrow this down
}

export const fetcher = async <T>(
  url: string,
  options?: AxiosRequestConfig,
  accessToken?: string
) => {
  return axios<T>({
    baseURL: API_BASE_URL,
    url,
    method: 'GET' as const,
    headers: { ...(await getHeaders(accessToken)) },
    ...options,
  }).then(({ data }) => data);
};

/**
 * Uses "until" wrapper to return { error , data }. For better typesafety, do not destructure the response.
 */
export const fetcherV2 = <T, E = GetErrorResult>(
  url: string,
  options?: AxiosRequestConfig,
  accessToken?: string
) => {
  return until<E, T>(async () => {
    const result = await axios<T>({
      url,
      method: 'GET' as const,
      headers: { ...(await getHeaders(accessToken)) },
      ...options,
    });
    if (result instanceof AxiosError) {
      // throwing here enforces result to be inside the "error" object
      throw result.response.data;
    }
    return result.data;
  });
};

export const getHeaders = async (accessToken?: string) => {
  let token = accessToken;
  if (!token) {
    const { access_token } = (await getSession()) || {};
    token = access_token;
  }
  return {
    Authorization: token ? `Bearer ${token}` : '',
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
};

interface CallAPI extends AxiosRequestConfig {
  url: string;
  req?: NextApiRequest;
}

/**
 *
 * Function used for fetching data server side. For calling in serverside, req arguments
 * need to be passed, else the fetch would be done in the client
 */
export const callAPISSR = async <Payload>({
  url,
  req,
  headers = {},
  method = 'GET',
  data,
  params,
}: CallAPI): Promise<AxiosResponse<Payload>> => {
  let accessToken;

  const isServerSideCall = !!req;

  if (isServerSideCall) {
    accessToken = (await getToken({ req }))?.access_token;
  }
  return axios({
    baseURL: API_BASE_URL,
    url,
    method,
    headers: {
      ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
      ...headers,
    },
    data: data || {},
    params: params || {},
  });
};

export const getInfiniteKey =
  (url: string, limit: number, options?: AxiosRequestConfig, searchBy?: string) =>
  (pageIndex, previousPageData) => {
    const offset = pageIndex * limit;
    const totalCount = previousPageData?.totalCount;
    if (offset && totalCount && offset >= totalCount) {
      // reached the last page
      return null;
    }
    const page = new URL(url, env.NEXT_PUBLIC_DOMAIN);
    if (limit && (offset || offset === 0)) {
      page.searchParams.append('offset', offset.toString());
      page.searchParams.append('limit', limit.toString());
      if (searchBy) {
        page.searchParams.append('searchBy', searchBy.toString());
      }
    }

    const paginatedUrl = `${page.pathname}${page.search}`;
    return [paginatedUrl, options]; // SWR key
  };

/**
 * https://swr.vercel.app/docs/revalidation#disable-automatic-revalidations
 */
export const useAPIImmutable = <Payload = unknown, Body = unknown>(
  url: string,
  shouldFetch = true,
  options: AxiosRequestConfig<Body> = {},
  swrOptions?: object
) => {
  const accessToken = useAtomValue(tokenAtom);
  const { data, error, mutate } = useSWRImmutable<Payload, APIErrorType>(
    shouldFetch ? [url, options, accessToken] : null,
    ([url, options, accessToken]: [
      url: string,
      options: AxiosRequestConfig<Body>,
      accessToken?: string
    ]) => fetcher(url, options, accessToken),
    { ...swrOptions }
  );
  return {
    data,
    isLoading: !error && !data && !!shouldFetch,
    isError: error && axios.isAxiosError(error) ? error.toJSON() : error,
    isIdle: !shouldFetch,
    mutate,
    key: url,
  };
};

export const useAPIInfinite = <Payload = unknown>(
  getKey: SWRInfiniteKeyLoader,
  shouldFetch = true
) => {
  const { data, error, isValidating, isLoading, mutate, size, setSize } = useSWRInfinite<Payload>(
    getKey,
    ([paginatedUrl, options]) => fetcher(paginatedUrl, options)
  );
  return {
    data,
    isLoading,
    isError: error && axios.isAxiosError(error) ? error.toJSON() : error,
    isIdle: !shouldFetch,
    mutate,
    size,
    setSize,
    isValidating,
  };
};

export const useAPI = <Payload = unknown, Body = unknown>(
  url: string,
  shouldFetch = true,
  options: AxiosRequestConfig<Body> = {},
  swrOptions?: object
) => {
  const accessToken = useAtomValue(tokenAtom);
  const { data, error, mutate } = useSWR<Payload, APIErrorType>(
    shouldFetch ? [url, options, accessToken] : null,
    ([url, options, accessToken]: [
      url: string,
      options: AxiosRequestConfig<Body>,
      accessToken?: string
    ]) => fetcher(url, options, accessToken),
    { ...swrOptions }
  );
  return {
    data,
    isLoading: !error && !data && !!shouldFetch,
    isError: error && axios.isAxiosError(error) ? error.toJSON() : error,
    isIdle: !shouldFetch,
    mutate,
    key: url,
  };
};

export const callNextApi = async <T>(request: AxiosRequestConfig, accessToken?: string) => {
  const config = {
    ...request,
    baseURL: `${env.NEXT_PUBLIC_DOMAIN}/api/`,
    headers: { ...(await getHeaders(accessToken)), ...request.headers },
  };
  return await axios<T>(config);
};

/**
 *
 * Function used for fetching data client side. Will attach access token from session object
 */
export const fetchService = async <Payload = APIPayload>(
  requestObj: AxiosRequestConfig,
  accessToken?: string
): Promise<{
  data: Payload;
  status: number;
  error?: string;
}> => {
  const config: AxiosRequestConfig = {
    baseURL: API_BASE_URL,
    ...requestObj,
    headers: { ...(await getHeaders(accessToken)), ...requestObj.headers },
  };
  try {
    const response = await axios(config);
    if (response) {
      const { data, status } = response;
      return {
        status,
        data,
      };
    } else if (response === undefined) {
      return {
        data: null,
        status: 401,
      };
    }
  } catch (e) {
    return {
      data: null,
      status: e.status || 500,
      error: JSON.stringify(e),
    };
  }
};

export const uploadFile = async (formdata: any, url: string) => {
  const session = await getSession();

  const config: any = {
    baseURL: API_BASE_URL,
    headers: {
      Authorization: !!session ? `Bearer ${session.access_token}` : '',
    },
  };
  try {
    const response = await axios.post(url, formdata, config);
    if (response) {
      const { data, status } = response;
      return {
        status,
        data,
      };
    } else if (response === undefined) {
      return {
        data: null,
        status: 401,
      };
    }
  } catch (e) {
    return {
      ...(e as {}),
      data: null,
    };
  }
};

export const getErrorMessage = (e) => {
  return e?.message || e?.response?.data?.message || GET_UNHANDLED_ERROR;
};

export const validateSchema = async (
  obj,
  schema: ZodTypeAny,
  errorSchema?: z.AnyZodObject | z.ZodOptional<z.AnyZodObject>
) => {
  const parseable = typeof obj === 'string' ? JSON.parse(obj) : obj;
  if (errorSchema) {
    return await errorSchema.safeParseAsync(parseable);
  }
  return await schema.parseAsync(parseable);
};
