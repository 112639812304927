import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { toast } from 'sonner';

function ErrorToasterListener() {
  const {
    query: { error, success, info, ...rest },
    replace,
  } = useRouter();

  useEffect(() => {
    if (error || success || info) {
      setTimeout(() => {
        if (info) {
          toast(info);
        } else {
          toast[error ? 'error' : 'success'](error || success || info);
        }
      }, 1);
      replace({ query: rest });
    }
  }, [error || success || info]);

  return null;
}

export default ErrorToasterListener;
