import React, { ReactNode } from 'react';
import { useToggle } from '@/utils/hooks';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Card, Divider, IconButton, SxProps, Theme } from '@mui/material';
import MyModal, { MyModalProps } from '.';
import { Subtitle } from '../Typography';

export type GenericModalProps = Partial<MyModalProps> & {
  title: string | ReactNode;
  children: ReactNode;
  onClose?: () => void;
  sx?: SxProps;
  innerModalSx?: SxProps;
  open?: boolean;
  classes?: {
    title?: SxProps;
  };
};

function GenericModal(props: GenericModalProps) {
  const { title, children, onClose, sx = {}, innerModalSx = {}, classes = {} } = props;
  const { on: open, setOff } = useToggle({
    initialState: !!props.open,
  });
  const handleClose = () => {
    onClose?.();
    setOff();
  };
  return (
    <MyModal
      title={'generic-modal'}
      description={'generic-modal'}
      open={open}
      sx={innerModalSx}
      onClose={handleClose}
    >
      <Card sx={{ ...styles.root, ...sx }}>
        <Box sx={styles.login}>
          <Subtitle variant="h6" sx={classes?.title || {}}>
            {title}
          </Subtitle>
          <IconButton
            color={'primary'}
            sx={{ boxShadow: 0 }}
            onClick={handleClose}
            id={'close-modal-button'}
          >
            <CloseIcon width={24} />
          </IconButton>
        </Box>
        <Divider />
        <Box
          sx={{
            p: '1rem 1rem 2rem 1rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          {children}
        </Box>
      </Card>
    </MyModal>
  );
}

const styles = {
  root: {
    borderRadius: '10px',
  },
  login: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    p: { xs: '.5rem', md: '0.5rem 1rem' },
    color: ({ palette }: Theme) => palette.primary.main,
  },
};

export default GenericModal;
