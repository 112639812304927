import React from 'react';
import Image from 'next/image';
import { IS_PRODUCTION } from '@/config/Constants';
import { getAssets } from '@/services/minio.service';
import { Box, Fade, SxProps, Tooltip } from '@mui/material';

function MobileAppLinks({ sx = {} }: { sx?: SxProps }) {
  const universalLink = IS_PRODUCTION
    ? 'https://smatapp.app.link/app'
    : 'https://smatapp.test-app.link/app';
  return (
    <Box
      sx={{
        gap: '1rem',
        justifyContent: { xs: 'center', md: 'flex-end' },
        display: 'flex',
        width: '100%',
        ...sx,
      }}
    >
      <Tooltip placement="top" arrow title={'Download on Apple store'}>
        <a href={universalLink} rel={'noopener'} target={'_blank'}>
          <Fade in timeout={2500}>
            <span>
              <Image
                src={getAssets('app_store.webp')}
                alt={'APP Store'}
                height="76"
                width="256"
                style={{
                  width: '100%',
                  maxWidth: '120px',
                  height: 'auto',
                }}
              />
            </span>
          </Fade>
        </a>
      </Tooltip>
      <Tooltip placement="top" arrow title={'Download on Google store'}>
        <a href={universalLink} rel={'noopener'} target={'_blank'}>
          <Fade in timeout={2500}>
            <span>
              <Image
                src={getAssets('google_play.webp')}
                alt={'Google Play'}
                height="76"
                width="256"
                style={{
                  width: '100%',
                  maxWidth: '120px',
                  height: 'auto',
                }}
              />
            </span>
          </Fade>
        </a>
      </Tooltip>
    </Box>
  );
}

export default MobileAppLinks;
