import { ComponentProps, ReactNode, SyntheticEvent, forwardRef } from 'react';
import ImageNext from 'next/image';
import Image from 'next/legacy/image';
import { getAssets } from '@/services/minio.service';
import { sora } from '@/themes/customizations';
import { GetLoginImagesReturn } from '@/utils/images';
import { Box, SxProps } from '@mui/material';
import { GA4_CUSTOM_EVENTS, GA4_EVENT_NAMES } from '@smatio/commons';
import { trackGA4Event } from '../Analytics';
import { Title } from '../Typography';

interface LogoProps {
  images?: GetLoginImagesReturn;
  black?: boolean;
  sx?: SxProps;
  onClick?: (e?: SyntheticEvent<HTMLDivElement>) => void;
  href?: string;
  anchorProps?: Partial<ComponentProps<'a'>>;
}

export const SmatLogo = forwardRef(function Logo(
  { images = undefined, black = true, sx = {}, onClick, href }: LogoProps,
  ref
) {
  const props = images
    ? { ...images[black ? 'logoBlack' : 'logoWhite'] }
    : { src: getAssets(black ? 'v2/v2-logo-tp.webp' : 'v2/v2-logo.webp') };
  const handleLogoClick = (e) => {
    trackGA4Event({
      category: GA4_CUSTOM_EVENTS.Navigation,
      action: GA4_EVENT_NAMES[GA4_CUSTOM_EVENTS.Navigation].Home,
    });
    onClick?.(e);
  };
  return (
    <Box
      onClick={handleLogoClick}
      component={'a'}
      ref={ref}
      sx={{
        width: { xs: '12rem', md: '24rem' },
        height: { xs: '6rem', md: '9rem' },
        ...sx,
      }}
      {...(href && { href })}
    >
      <Image
        alt="Smat logo"
        priority
        {...props}
        placeholder={images ? 'blur' : undefined}
        layout="fixed"
        height={48}
        width={160}
      />
    </Box>
  );
});

export const SmatIsoLogo = forwardRef(function Logo(
  { onClick = undefined, images = undefined, sx = {}, anchorProps = {} }: Omit<LogoProps, 'black'>,
  ref
) {
  const props = images
    ? { ...images.isologo, width: 100, height: 86 }
    : { src: getAssets('v2/v2-isologo.webp') };

  const handleLogoClick = (e) => {
    if (typeof gtag !== 'undefined') {
      trackGA4Event({
        category: GA4_CUSTOM_EVENTS.Navigation,
        action: GA4_EVENT_NAMES[GA4_CUSTOM_EVENTS.Navigation].Home,
      });
      onClick?.(e);
    }
  };
  return (
    <Box
      onClick={handleLogoClick}
      // @ts-expect-error ref
      ref={ref}
      component={anchorProps.href ? 'a' : 'figure'}
      sx={{
        bgcolor: 'common.white',
        width: '100px',
        borderRadius: '30px',
        padding: '10px 10px',
        position: 'relative',
        ...sx,
      }}
      {...anchorProps}
    >
      <ImageNext
        width={100}
        height={86}
        className="w-full h-auto mt-1 sm:lg-0"
        alt="Smat logo"
        priority
        placeholder={images ? 'blur' : undefined}
        sizes="100px"
        {...props}
      />
    </Box>
  );
});

export const SmatIsologoWithText = ({
  images,
  sx = {},
  text,
}: {
  images?: GetLoginImagesReturn;
  sx?: SxProps;
  text?: ReactNode;
}) => {
  const src = images ? { ...images.isologo } : { src: getAssets('v2/v2-isologo.webp') };
  return (
    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', ...sx }}>
      <Box
        component={'span'}
        sx={{
          bgcolor: 'common.white',
          width: '55px',
          aspectRatio: '1/1',
          borderRadius: '5px',
          padding: '6px 10px',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Image
          layout="intrinsic"
          width={678}
          height={583}
          alt="Smat logo"
          priority
          {...src}
          placeholder={images ? 'blur' : undefined}
        />
      </Box>
      <Title sx={{ fontFamily: sora.style.fontFamily, fontSize: '2rem' }}>{text || 'Smat'}</Title>
    </Box>
  );
};

export const BackgroundImage = ({ image, position = 'right', rotate = true, sx = {} }) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        inset: {
          xs: `0 ${position === 'left' ? '25%' : 0} 0 ${position === 'right' ? '25%' : 0}`,
          md: `0 ${position === 'left' ? '55%' : 0} 0 ${position === 'right' ? '55%' : 0}`,
        },
        zIndex: -1,
        opacity: 0.6,
        ...(rotate && { transform: 'rotate(180deg)' }),
        '& > span > img': {
          WebkitMaskImage: `-webkit-gradient(linear, ${position} top, ${position} bottom, from(rgba(0,0,0,1) 80&), to(rgba(0,0,0,0)))`,
          maskImage: `linear-gradient(to ${position}, rgba(0,0,0,1) 80%, rgba(0,0,0,0))`,
        },
        ...sx,
      }}
    >
      <Image layout={'fill'} alt={''} {...image} placeholder={'blur'} />
    </Box>
  );
};
