import { areUserAndProjectSameCompany, isInvestorUser } from '@/config';
import { SessionUser } from '@/types/next-auth';
import { FEATURES } from '@/utils/features';
import {
  ProjectListingMarketType,
  ProjectStatus,
  InvestmentGroup,
  InvestmentType,
  JsonFieldsStruct,
  ProjectInputGroup,
  MasterProject,
  ProjectData,
  isMasterProjectTypeGuard,
  isPublicProjectTypeGuard,
  jsonFieldsTypeGuard,
  showFormattedNames,
  ProjectDetails,
} from '@smatio/commons';

export const getIsProjectAvailableForSubscription = (project) => {
  return (
    project?.projectListingMarketType === ProjectListingMarketType.PRIMARY.toString() &&
    project.status === ProjectStatus.OPEN &&
    project.fundRaisingExpireInDays > 0 &&
    project?.availableBalance > 0
  );
};

export const getIsProjectFundRaisingPeriodEnded = (project: MasterProject) => {
  return (
    project?.projectListingMarketType === ProjectListingMarketType.PRIMARY.toString() &&
    (project.fundRaisingExpireInDays <= 0 || project?.availableBalance <= 0) &&
    ![ProjectStatus.IN_REVIEW_BY_COMMITTEE, ProjectStatus.DRAFT, ProjectStatus.PENDING].includes(
      project?.status
    )
  );
};

/**
 * Checks if project lister is owner of the current project
 * @returns boolean
 */
export const isProjectCreatedByCurrentUser = (createdBy: string, user: SessionUser) => {
  if (!createdBy || !user) return null;
  return user?.sub === createdBy;
};

/**
 * User can perform an owner action if is project lister or belongs to the same company
 * @param project
 * @param user
 * @returns
 */
export const userCanDoProjectAction = (project: ProjectData, user: SessionUser) => {
  if (
    isProjectCreatedByCurrentUser(project?.createdBy, user) &&
    project?.status !== ProjectStatus.TERMINATED
  ) {
    return true;
  }
  if (areUserAndProjectSameCompany(user, isMasterProjectTypeGuard(project) && project)) {
    return [ProjectStatus.PENDING, ProjectStatus.DRAFT]?.includes(project?.status);
  }
  return false;
};

export const isProjectShareable = (project: ProjectData) => {
  if (isMasterProjectTypeGuard(project) && project?.isPrivateProject) {
    return false;
  }
  return project?.status === ProjectStatus.LISTED || project?.status === ProjectStatus.OPEN;
};

export const userCanDoPreference = (project: ProjectData, user: SessionUser) => {
  const createdByCurrentUser = isProjectCreatedByCurrentUser(project?.createdBy, user);

  if (!createdByCurrentUser || !isMasterProjectTypeGuard(project)) {
    return true;
  }

  return project?.isPrivateProject;
};

export const shouldBlurProjectData = (user: SessionUser) => {
  return !!(FEATURES.BlurPublicContent && !user) || isInvestorUser(user.authorities);
};

export const getCardSubtitle = (project: ProjectData): string => {
  if (!project) return '';

  const { investmentType, investmentGroup, projectListingType } = project || {};
  if (
    investmentType === InvestmentType.FUND ||
    investmentGroup === InvestmentGroup.COLLECTIVE_INVESTMENT
  ) {
    return `${showFormattedNames(projectListingType)}`;
  }
  return `${showFormattedNames(investmentType)} - ${showFormattedNames(projectListingType)}`;
};

export const isFundProject = (data: ProjectData): boolean => {
  return (
    !!data.fundProject &&
    (data?.investmentType === InvestmentType.FUND ||
      data?.investmentGroup === InvestmentGroup.COLLECTIVE_INVESTMENT)
  );
};

export const isOpenProject = (project: ProjectData) => {
  return (
    project?.status === ProjectStatus.OPEN ||
    project?.status === ProjectStatus.LISTED ||
    project?.status === ProjectStatus.APPROVED
  );
};

export const isPublicProject = (project: ProjectData) => {
  // @ts-expect-error check
  return isPublicProjectTypeGuard(project) || !project?.isPrivateProject;
};

/**
 * Used to get the specific key used to access the project details considering the type of project
 * @param project ProjectData
 * @returns keyof ProjectDetails
 */
export const getProjectDetailsKey = (project?: ProjectData): keyof ProjectDetails => {
  if (isFundProject(project)) return 'fundProject';
  if (!!project.privateEquityProject) return 'privateEquityProject';
  if (!!project.artProject) return 'artProject';
  if (!!project.commoditiesProject) return 'commoditiesProject';
  if (!!project.infrastructureProject) return 'infrastructureProject';
  return 'realStateProject';
};

export const getProjectJsonFieldsAsArray = (
  project: ProjectData,
  step?: ProjectInputGroup
): Array<JsonFieldsStruct> => {
  return (
    (jsonFieldsTypeGuard(project) &&
      project.jsonFields[step] &&
      Object.values(project.jsonFields[step])) ||
    []
  );
};
