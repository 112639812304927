import { SvgIcon } from '@mui/material';

const TwitterIcon = (props) => {
  return (
    <SvgIcon
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'center',
        p: '.2rem',
        '&:hover': { '& > *': { fill: ({ palette }) => palette.primary.main } },
        '& > *': {
          fill: ({ palette }) => (palette.mode === 'light' ? palette.common.gray : '#FFFFFF'),
        },
      }}
    >
      <svg
        xmlns="http://www.w3.org/1999/xlink"
        viewBox="0 0 507.91 446.91"
        role="none"
        width={props.width || '100%'}
        height={props.height || '100%'}
        stroke={props.stroke || '#2c3e50'}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={props.color || 'black'}
        {...props}
      >
        <title>twitter</title>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
        />
      </svg>
    </SvgIcon>
  );
};

export default TwitterIcon;
