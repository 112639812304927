import { useEffect, useState } from 'react';
import { useHtmlElementClassObserver, useToggle } from '@/utils/hooks';
import storage from '@/utils/storage';
import { Stack } from '@mui/material';
import { Paragraph } from '../Typography';
import GenericModal from './GenericModal';

const GoogleTranslateWarningModal = () => {
  const htmlElementClassNames = useHtmlElementClassObserver(document.documentElement);

  const [hasModalBeenSeen, setHasModalBeenSeen] = useState(
    !!storage.getFromLocalStorage('smat-translate-wrn') ?? false
  );
  const { on: open, toggle: setOpen } = useToggle({ initialState: false });

  const shouldOpen = open && !hasModalBeenSeen;

  useEffect(() => {
    if (htmlElementClassNames.some((className) => className.startsWith('translated'))) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [htmlElementClassNames]);

  return shouldOpen ? (
    <GenericModal
      onClose={() => {
        setOpen(false);
        storage.saveInLocalStorage('smat-translate-wrn', 1);
        setHasModalBeenSeen(true);
      }}
      title={'Google transalte warning'}
      open={shouldOpen}
      innerModalSx={{ width: 'minmax(300px, 80vw)' }}
      classes={{ title: { color: 'warning.main' } }}
    >
      <Stack spacing={2}>
        <Paragraph>
          Due to a bug in Google Chrome, if you use Google Translate, you may experience errors
          while using this application. If experience unexpected errors you can disable Google
          Translate or use a different browser. We&apos;re sorry for the inconvenience and are
          working to remedy this issue.
        </Paragraph>
      </Stack>
    </GenericModal>
  ) : null;
};

export default GoogleTranslateWarningModal;
