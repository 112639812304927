export const FEATURES = {
  ForceClean: !!process.env.NEXT_PUBLIC_FLAG_FORCE_CLEAN,
  ProjectStats: !!process.env.NEXT_PUBLIC_FLAG_PROJECT_STATS,
  QuestionAndAnswer: !!process.env.NEXT_PUBLIC_FLAG_PROJECT_QUESTIONS,
  DigitalAssets: !!process.env.NEXT_PUBLIC_FLAG_DIGITAL_ASSETS,
  BlurPublicContent: !!process.env.NEXT_PUBLIC_FLAG_BLUR_PUBLIC_CONTENT,
  OrganizationsAsTeams: !!process.env.NEXT_PUBLIC_FLAG_ORGANIZATIONS_AS_TEAMS,
  DistributorNotifications: !!process.env.NEXT_PUBLIC_FLAG_DISTRIBUTOR_NOTIFICATIONS,
  TipTapMedia: !!process.env.NEXT_PUBLIC_FLAG_TIPTAP_MEDIA,
};
