import React from 'react';
import ErrorPage from '@/containers/Error/ErrorPage';
import HomeLayout from '@/layouts/home.layout';
import { clientLogger } from '@/utils/logger';
import { Box, Link, Stack } from '@mui/material';
import { LogSeverity } from '@smatio/commons';
import { Title } from '../Typography';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(_error) {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    clientLogger({
      pathname: window?.location?.href ?? '/error',
      message: {
        msg: error?.message || 'Rendering error reached boundary',
        error,
        stack: errorInfo?.componentStack,
      },
      severity: LogSeverity.CRITICAL,
    });
  }
  render() {
    // Check if the error is thrown
    // @ts-expect-error
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <HomeLayout>
          <ErrorPage
            status={500}
            title={'This is awkward 😓'}
            subtitle={
              <Stack>
                <Title color={'common.gray'} align="left" variant={'h6'}>
                  This appears to be an error from our side <br /> Please reach out to us to fix
                  this issue ASAP
                </Title>
                <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                  <Link
                    sx={{
                      width: 'fit-content',
                      color: ({ palette }) => `${palette.text.primary} !important`,
                    }}
                    href={'mailto:devs@smat.io'}
                  >
                    devs@smat.io
                  </Link>
                </Box>
              </Stack>
            }
            onClick={() => this.setState({ hasError: false })}
          />
        </HomeLayout>
      );
    }
    // Return children components in case of no error
    // @ts-expect-error
    return this.props.children;
  }
}

export default ErrorBoundary;
