import React, { ReactNode } from 'react';
import { clientLogger } from '@/utils/logger';
import { LogSeverity } from '@smatio/commons';

class GoogleTranslateBoundary extends React.Component<
  { fallback?: ReactNode; children: ReactNode },
  { hasError?: boolean }
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    clientLogger({
      pathname: window?.location?.href ?? '/error',
      // https://github.com/facebook/react/issues/11538
      message: {
        msg: error?.message || 'Rendering error caused by Google Translate',
        error,
        stack: info?.componentStack,
      },
      severity: LogSeverity.CRITICAL,
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.props.fallback;
    }
    return this.props.children;
  }
}
export default GoogleTranslateBoundary;
