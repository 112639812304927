import { WritableAtom, atom } from 'jotai';
import { getCookieConsentValue } from 'react-cookie-consent';
import { isApprovedUser, isContractFullySigned, isPendingUser } from '@/config';
import { PROJECTS_TAB, PROJECT_OVERVIEW_TABS } from '@/config/Constants';
import { SessionUser } from '@/types/next-auth';
import '@smatio/commons';
import { NotificationsRes, MasterProject } from '@smatio/commons';

export const cookieConsentAtom = atom<boolean>(
  getCookieConsentValue('SMAT_cookie_accept') === 'true'
);

export const idleUserAtom = atom<boolean>(false);

export const popupAtom: WritableAtom<Window | null, [Window], void> = atom(
  null,
  (_get, set, popup) => {
    set(popupAtom, popup);
  }
);

export const setIdleUserAtom = atom(null, (_get, set, newValue: boolean) => {
  set(idleUserAtom, newValue);
});

export const userAtom: WritableAtom<SessionUser, [SessionUser], void> = atom(
  null,
  (_get, set, user) => {
    set(userAtom, user);
  }
);

export const tokenAtom: WritableAtom<string | null, [string | null], void> = atom(
  null,
  (_get, set, token) => {
    set(tokenAtom, token);
  }
);

export const toggleLoginModal = atom<boolean>(false);
export const blurProjectData = atom<boolean>(false);

export const notificationsAtom: WritableAtom<NotificationsRes, [NotificationsRes], void> = atom(
  null,
  (_get, set, notificationResponse) => {
    set(notificationsAtom, notificationResponse);
  }
);

export const showAgreementTooltipAtom = atom((get) => {
  const user = get(userAtom);
  return (
    !isContractFullySigned(user) ||
    (isApprovedUser(user?.status) && !isContractFullySigned(user)) ||
    isPendingUser(user?.status)
  );
});

export const toggleProjectSidebar = atom<boolean>(false);

export const projectStatusTabsAtom = atom<PROJECTS_TAB>(PROJECTS_TAB.Current);
export const projectOverviewTabsAtom = atom<PROJECT_OVERVIEW_TABS>(PROJECT_OVERVIEW_TABS.Overview);

export const setProjectTabATom = atom(null, (_get, set, newValue: PROJECTS_TAB) => {
  set(projectStatusTabsAtom, newValue);
});

export const projectDetailAtom: WritableAtom<MasterProject, [MasterProject], void> = atom(
  null,
  (_get, set, project) => {
    set(projectDetailAtom, project);
  }
);

export const initalMarketplaceProjectsAtom = atom(null, (_get, set, project) => {
  set(initalMarketplaceProjectsAtom, project);
});

/**
 * @deprecated fix done in API
 */
function _removeDuplicatesByMasterProjectId(projects) {
  const uniqueProjects = projects.reduce((acc, project) => {
    const existingProject = acc.find((item) => item.masterProjectId === project.masterProjectId);
    if (!existingProject) {
      acc.push(project);
    }
    return acc;
  }, []);

  return uniqueProjects;
}
