import { ReactNode } from 'react';
import { SxProps } from '@mui/material';
// import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: 330, md: 400 },
  minWidth: 330,
};

export interface MyModalProps {
  children: ReactNode;
  onClose?: () => void;
  open: boolean;
  title?: string;
  description?: string;
  sx?: SxProps;
}

const MyModal: React.FC<MyModalProps> = ({
  open,
  onClose,
  children,
  title,
  description,
  sx = {},
}) => {
  return (
    <Modal
      disableEnforceFocus
      id={'transition-modal'}
      aria-labelledby={title ?? 'transition-modal'}
      aria-describedby={description ?? 'transition-modal-description'}
      open={open}
      onClose={onClose}
      closeAfterTransition
      disableAutoFocus
    >
      <Fade in={open}>
        <Box sx={{ ...style, ...sx }}>{children}</Box>
      </Fade>
    </Modal>
  );
};

export default MyModal;
