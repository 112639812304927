import { useEffect, useState } from 'react';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';
import AutocompleteMultiselect from '@/components/Inputs/MultiselectAutocomplete';
import SelectTextfield from '@/components/Inputs/SelectTextfield';
import { MuiSpinner } from '@/components/Spinner';
import { IS_DEVELOPMENT } from '@/config/Constants';
import { callPreferencesCrmPerson, callUpdateCrmPerson } from '@/services/api.service';
import { fireErrorToast, onInvalid } from '@/utils';
import { useToggle } from '@/utils/hooks';
import { DevTool } from '@hookform/devtools';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Fade, List, ListItem, ListItemText, Skeleton, SxProps } from '@mui/material';
import { INPUTS_TYPES } from '@smatio/commons';

export const PreferencesFormSchema = z.object({
  interestedInPrivateMarkets: z.number().or(z.string()),
  interestedInCommunityNews: z.number().or(z.string()),
  interestedInPrivateMarketEvents: z.number().or(z.string()),
  interestedInDigitalAssetEvents: z.number().or(z.string()),
  assetClassInterest: z
    .array(z.object({ label: z.string(), value: z.number().or(z.string()) }))
    .optional(),
  categoryOfInvestmentInterest: z
    .array(z.object({ label: z.string(), value: z.number().or(z.string()) }))
    .optional(),
});

const FormSchema = PreferencesFormSchema;

export const FormQuestions = () => {
  const { data, update } = useSession();
  const { pathname } = useRouter();
  const { on: loading, toggle: setLoading } = useToggle();
  const [isLoading, setIsLoading] = useState(true);
  const { control, handleSubmit, setValue, formState } = useForm({
    resolver: zodResolver(FormSchema),
  });

  const onSubmit: SubmitHandler<z.infer<typeof FormSchema>> = async (formD) => {
    try {
      setLoading(true);
      const formData = {
        ...formD,
        ...(formD.categoryOfInvestmentInterest?.length
          ? {
              interestedInInvestmentOpportunitiesInPrivateMarkets: 'Yes',
              categoryOfInvestmentInterest: formD.categoryOfInvestmentInterest
                .map((v) => v.value)
                .join(','),
            }
          : { interestedInInvestmentOpportunitiesInPrivateMarkets: 'No' }),
        ...(formD.assetClassInterest?.length && {
          assetClassInterest: formD.assetClassInterest.map((v) => v.value).join(','),
        }),
        email: data?.user?.username || data?.user?.email,
      };
      // @ts-expect-error fixme
      const { data: resData } = await callUpdateCrmPerson(formData);
      if (resData?.status === 200) {
        await update();
        toast.success('Preferences updated');
      } else {
        // @ts-expect-error fixme
        throw new Error(resData?.error || 'Error updating preferences');
      }
    } catch (e) {
      fireErrorToast(e?.message, pathname);
    } finally {
      setLoading(false);
    }
  };

  const isFormDirty = !!Object.keys(formState.dirtyFields || {}).length;

  const sx: SxProps = {
    width: 300,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await callPreferencesCrmPerson(data?.user?.email);
        if (response && response.data) {
          const preferences = response.data.data;

          setValue('interestedInPrivateMarkets', preferences?.InterestedInPrivateMarkets, {
            shouldDirty: true,
          });
          setValue('interestedInCommunityNews', preferences?.InterestedInCommunityNews, {
            shouldDirty: true,
          });
          setValue(
            'interestedInPrivateMarketEvents',
            preferences?.InterestedInPrivateMarketEvents,
            { shouldDirty: true }
          );
          setValue('interestedInDigitalAssetEvents', preferences?.InterestedInDigitalAssetEvents, {
            shouldDirty: true,
          });
          const assetClassValues = preferences?.AssetClassInterest?.split(',').map(
            (value: string) => {
              const option = ASSET_CLASS_OPTIONS.find(
                (option) => option.value.toString() === value.trim()
              );
              return option ? { value: option.value, label: option.label } : null;
            }
          );

          setValue('assetClassInterest', assetClassValues, { shouldDirty: true });

          const categoryValues = preferences?.CategoryOfInvestmentInterest?.split(',').map(
            (value: string) => {
              const option = CATEGORY_OPTIONS.find(
                (option) => option.value.toString() === value.trim()
              );
              return option ? { value: option.value, label: option.label } : null;
            }
          );

          setValue('categoryOfInvestmentInterest', categoryValues, { shouldDirty: true });
        }
        if (response.status === 200) {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        throw new Error(error);
      }
    };

    if (data?.user?.email) {
      fetchData();
    }
  }, [data?.user?.email]);

  return (
    <>
      <List sx={{ bgcolor: 'background.paper' }}>
        {IS_DEVELOPMENT && <DevTool control={control} placement={'top-right'} />}

        {FORM_QUESTIONS.map((q) => {
          return (
            <>
              <ListItem
                key={q.label}
                disableGutters
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <ListItemText
                  sx={{ px: { xs: '1rem', md: 'unset' } }}
                  id={q.value}
                  primary={q.label}
                />
                {isLoading ? (
                  <Skeleton height={66} width={300} />
                ) : (
                  <Box>
                    {inputsMapping[q.type]?.({
                      control,
                      name: q.value,
                      options: q.options,
                      sx,
                      setValue,
                    })}
                  </Box>
                )}
              </ListItem>
            </>
          );
        })}
        <Fade in={isFormDirty} timeout={1500}>
          <Box sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-end' }, flex: 1 }}>
            <Button
              sx={{ ...sx, display: isFormDirty ? 'initial' : 'none' }}
              variant={'contained'}
              color="primary"
              size="small"
              type={'submit'}
              disabled={loading}
              onClick={handleSubmit(onSubmit, onInvalid)}
            >
              {loading ? (
                <MuiSpinner color={'inherit'} size={20} sx={{ minHeight: 0, paddingY: 0.3 }} />
              ) : (
                'Save preferences'
              )}
            </Button>
          </Box>
        </Fade>
      </List>
    </>
  );
};

const inputsMapping = {
  [INPUTS_TYPES.Select]: (props) => (
    <SelectTextfield required variant="outlined" size="small" {...props} native={false} />
  ),
  [INPUTS_TYPES.Multiselect]: (props) => (
    <AutocompleteMultiselect
      limitTags={1}
      required
      variant="outlined"
      size="small"
      value={props.name}
      {...props}
      onChange={(_e, v) => {
        props?.setValue(props.name, v);
      }}
    />
  ),
};

/**
 * Based on Pipedrive API [GET] /personFields
 */
export const ASSET_CLASS_OPTIONS = [
  {
    value: IS_DEVELOPMENT ? 67 : 122,
    label: 'Private Equity & Venture Capital',
  },
  {
    value: IS_DEVELOPMENT ? 68 : 123,
    label: 'Private Debt',
  },
  {
    value: IS_DEVELOPMENT ? 69 : 124,
    label: 'Real Estate',
  },
  {
    value: IS_DEVELOPMENT ? 70 : 125,
    label: 'Art',
  },
  {
    value: IS_DEVELOPMENT ? 71 : 126,
    label: 'Infrastructure',
  },
  {
    value: IS_DEVELOPMENT ? 72 : 127,
    label: 'Litigation',
  },
  {
    value: IS_DEVELOPMENT ? 84 : 152,
    label: 'Alternative Credit & Credit Hedge Fund',
  },
];

export const CATEGORY_OPTIONS = [
  {
    value: IS_DEVELOPMENT ? 64 : 119,
    label: 'Collective Investments (Funds)',
  },
  {
    value: IS_DEVELOPMENT ? 65 : 120,
    label: 'Club deal and direct Investments',
  },
  {
    value: IS_DEVELOPMENT ? 66 : 121,
    label: 'Digital Assets',
  },
];

// https://docs.google.com/forms/d/1PHuCJl3wBo122rwYPJrM4rrNoXICx4L99Qi1jqxIGBg/edit?ts=65a0f848
export const FORM_QUESTIONS = [
  {
    label: 'Interested in Private Markets?',
    value: 'interestedInPrivateMarkets',
    type: INPUTS_TYPES.Select,
    options: [
      {
        label: 'Yes, for investing and distributing',
        value: IS_DEVELOPMENT ? 59 : 114,
      },
      { label: 'Yes, only for investing', value: IS_DEVELOPMENT ? 60 : 115 },
      { label: 'No', value: IS_DEVELOPMENT ? 61 : 116 },
    ],
  },
  {
    label: 'Interested in receiving news from Smat.io community?',
    value: 'interestedInCommunityNews',
    type: INPUTS_TYPES.Select,
    options: [
      { label: 'Yes', value: IS_DEVELOPMENT ? 57 : 112 },
      { label: 'No', value: IS_DEVELOPMENT ? 58 : 113 },
    ],
  },
  {
    label:
      'Would you be interested in being invited to our Private Market educational events, notably co-hosted with our partner Sphere Media?',
    value: 'interestedInPrivateMarketEvents',
    type: INPUTS_TYPES.Select,
    options: [
      { label: 'Yes', value: IS_DEVELOPMENT ? 73 : 44 },
      { label: 'No', value: IS_DEVELOPMENT ? 74 : 45 },
    ],
  },
  {
    label: 'Interested in being invited to our educational events on digital assets?',
    value: 'interestedInDigitalAssetEvents',
    type: INPUTS_TYPES.Select,
    options: [
      { label: 'Yes', value: IS_DEVELOPMENT ? 34 : 46 },
      { label: 'No', value: IS_DEVELOPMENT ? 35 : 47 },
    ],
  },
  {
    label: 'Which asset classes are you most interested in?',
    value: 'assetClassInterest',
    type: INPUTS_TYPES.Multiselect,
    options: ASSET_CLASS_OPTIONS,
  },
  {
    label: 'Which category of investment are you the most interested in?',
    value: 'categoryOfInvestmentInterest',
    type: INPUTS_TYPES.Multiselect,
    options: CATEGORY_OPTIONS,
  },
] as const;
