// import { useSetAtom } from 'jotai';
import { useRouter } from 'next/router';
import CookieConsent from 'react-cookie-consent';

// import { setCookieConsent } from '@/redux/actions/user';
// import { cookieConsentAtom } from '@/services/atoms.service';

const Cookie = () => {
  // const setValue = useSetAtom(cookieConsentAtom);
  const { pathname } = useRouter();

  if (['/overview', '/deal-listing'].includes(pathname)) return null;

  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      cookieName="SMAT_cookie_accept"
      enableDeclineButton
      // onAccept={() => {
      //   setValue(true);
      // }}
      // onDecline={() => {
      //   setValue(false);
      // }}
      disableStyles
      declineButtonText="Decline"
      declineButtonStyle={{
        backgroundColor: '#ffffff',
        color: '#178097',
        border: 'none',
        padding: '8px 20px',
      }}
      buttonStyle={{
        backgroundColor: '#178097',
        color: '#ffffff',
        border: 'none',
        borderRadius: '30px',
        padding: '8px 20px',
      }}
      contentStyle={{
        color: 'black',
        display: 'flex',
        margin: '2rem 1rem',
        flex: '1 0',
        fontSize: '12px',
      }}
      buttonWrapperClasses="cookieModal__btnWrapper"
      containerClasses="cookieModal"
      expires={150}
    >
      <p>
        Smat uses cookies to ensure the best possible user experience on our platform. In order to
        remember things like your language preferences and location, we need to store some
        information and to do this we use cookies. These files are safe and secure and will never
        store any sensitive personal or account information.{' '}
        <a className="cookieModal__link" href="https://www.smat.io/privacy-policy">
          See our privacy policy
        </a>
      </p>
    </CookieConsent>
  );
};

export default Cookie;
