import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, HttpStatusCode } from 'axios';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { toast } from 'sonner';
import useSWR, { Fetcher } from 'swr';
import { IS_CI } from '@/config/Constants';
import { env } from '@/config/envs';
import { fireErrorToast, fireSuccessToast } from '@/utils';
import { ProjectDocumentsUnion } from '@smatio/commons';
import { API_BASE_URL, getHeaders } from './fetch.service';

export interface MinioFileResponse {
  fieldname: string;
  originalname: string;
  encoding: string;
  mimetype: string;
  bucket: string;
  key: string;
  acl: string;
  contentType: string;
  contentDisposition: null;
  storageClass: string;
  serverSideEncryption: null;
  metadata: {
    fieldName: string;
    date: string;
  };
  etag: string;
  versionId: null;
}

interface UploadToMinio {
  bucket?: string;
  imageName: string;
  file: File;
}

interface CallMinioAPIResponse<Payload> {
  data: Payload;
  status: HttpStatusCode;
  error?: AxiosError;
}

const minioAxios = axios.create({
  baseURL: '/api/minio',
  timeout: 30000,
});

export const deleteFromMinio = async ({ bucket = BUCKET, imageName, accessToken = '' }) => {
  const config = {
    params: {
      url: imageName,
      bucket,
    },
    method: 'DELETE' as const,
    headers: {
      ...(await getHeaders()),
      ...(accessToken && {
        Authorization: `Bearer ${accessToken}`,
      }),
    },
  };
  return await callMinioAPI(config);
};

/**
 * @deprecated
 * **DEPRECATED** Upload file to MinIO as a stream. Should be used with small files (less 1mb) and fast response time from NextJS API
 */
export const uploadToMinio = async ({ bucket = BUCKET, imageName, file }: UploadToMinio) => {
  const formData = new FormData();
  formData.append('file', file);
  const config = {
    data: formData,
    params: {
      imageName,
      bucket,
    },
    method: 'POST' as const,
    headers: { ...(await getHeaders()), 'content-type': 'multipart/form-data' },
  };
  return await callMinioAPI<{
    status: HttpStatusCode;
    message: string;
    file: MinioFileResponse[];
  }>(config);
};

/**
 * Fetch presigned upload URL from MinIO. Can be used to upload larger files directly from the clientside insted of NextJS API
 */
export const uploadToMinioWithPresigned = async ({
  bucket = BUCKET,
  imageName,
  file,
  accessToken = undefined,
}) => {
  const { data } = await callMinioAPI<{
    status: HttpStatusCode;
    url: string;
  }>({
    method: 'PUT' as const,
    headers: {
      ...(await getHeaders()),
      ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
    },
    params: {
      imageName,
      bucket,
    },
  });
  if (data?.status !== 200 || !data?.url) {
    return toast.error('Error getting presigned URL from Minio');
  }
  if (IS_CI) return;
  return axios(data.url, {
    method: 'PUT' as const,
    data: file,
  });
};

/**
 * Fetch function to get Presigned URL of an asset from a restricted minioBucket and access it publicly
 * @param {string} url - The relative path to the minio asset inside the bucket
 * @param {string} bucket - Optional bucket name
 */
export const getMinioSignedURL = async (url: string, bucket = BUCKET) => {
  return await callMinioAPI<{
    status: HttpStatusCode;
    url: string;
  }>({
    method: 'GET' as const,
    headers: { ...(await getHeaders()) },
    params: {
      bucket,
      url,
    },
  });
};

export const callMinioAPI = async <Payload>(
  config: AxiosRequestConfig
): Promise<CallMinioAPIResponse<Payload>> => {
  try {
    if (!config.headers) {
      config.headers = { ...(await getHeaders()) };
    }

    const response: AxiosResponse<Payload> = await minioAxios(config);
    if (response) {
      const { data, status } = response;
      return {
        status,
        data,
      };
    } else if (response === undefined) {
      return {
        data: null,
        status: 401,
      };
    }
  } catch (e) {
    return {
      data: null,
      status: e?.status || 500,
      error: e,
    };
  }
};

export const BUCKET = env.NEXT_PUBLIC_MINIO_BUCKET;
export const MINIO_PUBLIC_URL = `https://${env.NEXT_PUBLIC_MINIO_URL}/${BUCKET}/`;
export const FILE_DOWNLOAD_URL = API_BASE_URL + '/fetch/files?url=';
export const getAssets = (fileName: string): string =>
  `https://cdn.beta.smat.io/assets/${fileName}`;

type MinioPresignedRes = {
  status: HttpStatusCode;
  url: string;
};

export const minioFetcher: Fetcher<any, string> = async (url: string) => {
  const config = {
    method: 'GET' as const,
    headers: { ...(await getHeaders()) },
    params: {
      bucket: BUCKET,
      url,
    },
  };
  const { data } = await callMinioAPI<MinioPresignedRes>(config);

  return data;
};

export const useMinioResource = <Payload = MinioPresignedRes>(url: string, shouldFetch = true) => {
  const { data, error, mutate } = useSWR<Payload, unknown>(shouldFetch ? url : null, minioFetcher);
  return {
    data,
    isLoading: !error && !data && !!shouldFetch,
    isError: error && axios.isAxiosError(error) ? error.toJSON() : error,
    isIdle: !shouldFetch,
    mutate,
  };
};

export const bulkDownloadDocs = async (docs: ProjectDocumentsUnion, fileName: string) => {
  const loadingToast = toast.loading('Preparing documents...');
  try {
    const responses = await Promise.all(
      Object.keys(docs)
        .reduce((acc, document) => {
          const doc = docs[document];
          const documentUrl = typeof doc === 'string' ? doc : doc?.projectPicture;
          if (documentUrl && typeof documentUrl === 'string') {
            acc.push(`${FILE_DOWNLOAD_URL}${documentUrl}&&name=${documentUrl?.split('/')?.pop()}`);
          }
          return acc;
        }, [])
        .map((urlToFetch) =>
          //@ts-expect-error
          axios({
            url: urlToFetch,
            method: 'GET' as const,
            responseType: 'blob' as const,
            headers: { ...getHeaders() },
          })
        )
    );
    const blobs = responses.filter((res) => res);
    const zip = new JSZip();
    blobs.forEach((blob) => {
      const { config, data } = blob;
      const _fileName = config.url.split('=')?.pop();
      zip.file(_fileName, data);
    });
    zip.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(content, `${fileName || 'project-documents'}.zip`);
    });
    fireSuccessToast('Success!', { loading: loadingToast });
  } catch (e) {
    fireErrorToast(e?.message, window?.location?.pathname, { loading: loadingToast });
  }
};

export const resizeImage = async (
  file: File,
  baseUrl: string,
  fileExtension: string,
  masterProjectId: string,
  accessToken?: string
) => {
  const formData = new FormData();
  formData.append('buffer', file);
  formData.append('baseUrl', baseUrl);
  formData.append('fileExtension', fileExtension);
  formData.append('masterProjectId', masterProjectId);
  axios({
    url: '/api/editImage',
    method: 'POST',
    data: formData,
    headers: {
      ...(await getHeaders()),
      'Content-Type': 'multipart/form-data',
      ...(accessToken && {
        Authorization: `Bearer ${accessToken}`,
      }),
    },
  });
};
